import React from 'react'
import q1 from "../../images/image05-thumbnail.jpg";
import PaginatedItems from "../../Component/Pagination/Index";
import Layout from '../../Layout/DashboardLayout/Index';

export default function Index() {
  return (
  <Layout sidebar>
   <main className="enrollment">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 col-12 col-xl-12 col-lg-12">
              <div className="reviewBox">
                <div className="reviewLeft">
                  <h1 className="text-heading">Withdrawals</h1>
                </div>
              </div>
            </div>
            <div
              className="col-md-12 col-12 col-xl-12 col-lg-12"
              style={{ marginTop: 40 }}
            >
              <div className="card recentTable_Card">
                <div className="card-header">
                  <h3 className="">Recent Withdrawals</h3>
                </div>
                <div className="card-body p-0">
                  <div className="table-responsive">
                    <table className="table table-hover" cellPadding={10}>
                      <thead>
                        <tr>
                          <th className="text-left firsT_Column">Shop Name</th>
                          <th>Amount</th>
                          <th>Status</th>
                          <th>Created</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="text-left">Temper studios</td>
                          <td>€186.00</td>
                          <td>
                            <span className="pending_status">Pending</span>
                          </td>
                          <td>8 months ago</td>
                          <td>
                          <a href="#"> <i className="fa-solid fa-eye" /></a>
                          </td>
                        </tr>
                        <tr>
                          <td className="text-left">Temper studios</td>
                          <td>€186.00</td>
                          <td>
                            <span className="approved_Status">Approved</span>
                          </td>
                          <td>8 months ago</td>
                        <td>
                        <a href="#"> <i className="fa-solid fa-eye" /></a>
                          </td>
                        </tr>
                        <tr>
                          <td className="text-left">Temper studios</td>
                          <td>€186.00</td>
                          <td>
                            <span className="on_HoldStatus">On hold</span>
                          </td>
                          <td>8 months ago</td>
                          <td>
                            <i className="fa-solid fa-eye" />
                          </td>
                        </tr>
                        <tr>
                          <td className="text-left">Temper studios</td>
                          <td>€186.00</td>
                          <td>
                            <span className="processing_Status">Processing</span>
                          </td>
                          <td>8 months ago</td>
                          <td>
                            <i className="fa-solid fa-eye" />
                          </td>
                        </tr>
                        <tr>
                          <td className="text-left">Temper studios</td>
                          <td>€186.00</td>
                          <td>
                            <span className="reject_Status">Rejected</span>
                          </td>
                          <td>8 months ago</td>
                          <td>
                            <i className="fa-solid fa-eye" />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <PaginatedItems itemsPerPage={4}/> */}
      </main>
  </Layout>
  )
}
