import React, { useEffect, Profiler } from "react";
import { BrowserRouter as Router, Routes, Route, useNavigate, useLocation } from "react-router-dom";
import "./App.css";
import Dashboard from "../src/View/Dashboard/Index";
import DashboardLayout from "../src/Layout/DashboardLayout/Index"
import Login from '../src/Athentication/Login';
import Product from "../src/View/Products/Index";
import Categories from "../src/View/Categories/Index";
import Withdrawals from './View/Dashboard/Withdrawals';
import User from './View/User/User';
import CreateCategory from './View/Categories/CreateCategory';
import { useSelector } from "react-redux";
import EditCategory from "./View/Categories/EditCategory";
import CreateProduct from "./View/Products/CreateProduct";
import EditProduct from "./View/Products/EditProduct";
import Order from "../src/View/Order/Index";
import Orderstatus from "../src/View/OrderStatus/Index";
import Age from "../src/View/Age/Index";
import Review from "../src/View/Review/Index";
import Color from "../src/View/Color/Index";
import Vendor from "../src/View/Vendor/Index";
import CreateVendor from './View/Vendor/CreateVendor';
import Banner from "../src/View/Banner/Index";
import CreateBanner from './View/Banner/CreateBanner';
import Brands from "../src/View/Brands/Index";
import EditBrand from "./View/Brands/EditBrand";
import Pagination from "../src/Component/Pagination/Index"
import EditVendor from "./View/Vendor/EditVendor";
import CreateBrands from './View/Brands/CreateBrands';
import Questions from "../src/View/Questions/Index";
import Createsubcategory from "../src/View/SubCategory/CreateSubCategory";
import Subcategory from "../src/View/SubCategory/Index";
import EditSubCategory from "./View/SubCategory/EditSubCategory";
import Profile from "../src/View/Profile/Index";
import Withdrawal from "../src/View/Withdrawal/Index";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CreateOrderStatus from "./View/OrderStatus/CreatOrderStatus";
import EditOrderStatus from "./View/OrderStatus/EditOrderStatus";
import ManageBanner from "./View/Banner/ManageBanner";
import StaticBannerEdit from "./View/Banner/StaticBannerEdit";
import SidebannerEdit from "./View/Banner/SidebannerEdit";
import OrderDetail from "./View/Order/OrderDetail";
import Menu from "./View/Menu";
import ProductType from "./View/ProductType/Index";
import CreateProductType from "./View/ProductType/CreateProductType";
import EditProductType from "./View/ProductType/EditProductType.js";
import UserContact from "./View/UserContact";
import Partner from "./View/Partner";
import BulkProduct from "./View/BulkProduct";
import PopupBanner from "./View/PopupBanner";

function App() {
  const auth = useSelector((state) => state.auth);
  const navigate = useNavigate()
  let location = useLocation();
  let from = location.pathname || "/";

  useEffect(() => {
    if (auth.authenticate) {
      if (from === '/') {
        navigate('/')
      }
      else {
        navigate(from, { replace: true })
      }
    }
    else {
      navigate('/login')
    }
  }, [auth.authenticate])

  return (
    <React.Fragment>
      <ToastContainer />
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/" exact element={<Dashboard />} />
        <Route path="/products" element={<Product />} />
        <Route path="/categories" element={<Categories />} />
        <Route path="/withdrawals" element={<Withdrawals />} />
        <Route path="/user" element={<User />} />
        <Route path="/userContact" element={<UserContact />} />
        <Route path="/create-category" element={<CreateCategory />} />
        <Route path="/edit-category/:cat" element={<EditCategory />} />
        <Route path="/create-product" element={<CreateProduct />} />
        <Route path="/edit-product/:product" element={<EditProduct />} />
        <Route path="/order" element={<Order />} />
        <Route path="/:orderDetail" element={<OrderDetail />} />
        <Route path="/order-status" element={<Orderstatus />} />
        <Route path="/create-order-status" element={<CreateOrderStatus />} />
        <Route path="/edit-order-status/:orderStatus" element={<EditOrderStatus />} />
        <Route path="/age" element={<Age />} />
        <Route path="/review" element={<Review />} />
        <Route path="/color" element={<Color />} />
        <Route path="/vendor" element={<Vendor />} />
        <Route path="/create-vendor" element={<CreateVendor />} />
        <Route path="/edit-vendor/:vendor" element={<EditVendor />} />
        <Route path="/banner" element={<Banner />} />
        <Route path="/create-banner" element={<CreateBanner />} />
        <Route path="/brands" element={<Brands />} />
        <Route path="/create-brands" element={<CreateBrands />} />
        <Route path="/edit-brands/:brand" element={<EditBrand />} />
        <Route path="/questions" element={<Questions />} />
        <Route path="/subcategory" element={<Subcategory />} />
        <Route path="/create-subcategory" element={<Createsubcategory />} />
        <Route path="/edit-subcategory/:subCategory" element={<EditSubCategory />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/withdrawal" element={<Withdrawal />} />
        <Route path="/manage-banner/:banner" element={<ManageBanner />} />
        <Route path="/staticbanner-edit/:staticBanner" element={<StaticBannerEdit />} />
        <Route path="/sidebanner-edit" element={<SidebannerEdit />} />
        <Route path="/pagination" element={<Pagination />} />
        <Route path="/menu" element={<Menu />} />
        <Route path="/prodType" element={<ProductType />} />
        <Route path="/create-prodType" element={<CreateProductType />} />
        <Route path="/edit-types/:type" element={<EditProductType />} />
        <Route path="/partner" element={<Partner />} />
        <Route path="/bulkProduct" element={<BulkProduct />} />
        <Route path="/popupBanner" element={<PopupBanner />} />
        {/* <Route path="/edit-vendor" element={<CreateVendor/>} /> */}

        {/* <Route element={<DashboardLayout />}> */}

        {/* </Route> */}
      </Routes>
    </React.Fragment>
  );
}

export default App;
