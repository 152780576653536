import React from 'react'
import SearchableDropdown2 from "../../Component/SearchableDropdown/SearchableDropdown2";


export default function Withdrawals() {
    const data2 = ["Approved", "Onhold", "Processing", "Pending", "Rejected"]
    return (
        <>
            <main className="enrollment">
                <div className="withdrawal">
                    <h4 className="mb-4 withdrawal_Information">Withdrawal Information</h4>
                    <div className="card">
                        <div className="card-body">
                            <div className='col-12 col-xl-12 col-lg-12 col-md-12'>
                                <div className="row align-items-start custom_Row">
                                    <div className="form-group d-flex align-items-center  mb-2 custWithdraw" style={{ width: "100%", justifyContent: "space-between" }}>
                                        <div className="leftSec">
                                            <label htmlFor="" className="amount_label">Amount </label>
                                            <span className="colon">:</span>
                                            <input type="text" className="form-control amountInput" readOnly placeholder="186" />
                                        </div>
                                        <div className="RightSec">
                                            <SearchableDropdown2 data={data2} />
                                            <div className="change_Status">
                                                <button className="btn ">Change Status</button>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-xl-12 col-lg-12 col-md-12">
                                <div style={{ marginRight: "-15px", marginLeft: "-15px" }}>
                                    <div className="form-group d-flex align-items-center  mb-2 custPayment">
                                        <label htmlFor="" className="amount_label">Payment Method </label>
                                        <span className="colon">:</span>
                                        <span className=" nA">N/A</span>
                                    </div>
                                    <div className="form-group d-flex align-items-center  mb-2 custStatus">
                                        <label htmlFor="" className="amount_label">Status </label>
                                        <span className="colon">:</span>
                                        <span className=" nA">Pending</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row mt-4">
                        <div className="col-md-6">
                            <div className="card custom_DetailCard">
                                <div className="card-body">
                                    <h5 className="title_Details">Details :</h5>
                                    <p className="para_Details">Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts. Separated they live in Bookmarksgrove right at the coast of the Semantics.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="card custom_DetailCard">
                                <div className="card-body">
                                    <h5 className="title_Details">Note :</h5>
                                    <p className="para_Details">When she reached the first hills of the Italic Mountains, she had a last view back on the skyline of her hometown Bookmarksgrove, the headline of Alphabet Village and the subline of her own road, the Line Lane. Pityful a rethoric question ran over her cheek, then</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}
