import React, { useState } from "react";
import logo from "../../images/download.png";
import profile from "../../images/avatar-placeholder.svg";
import { useNavigate, useNavigation } from "react-router-dom";
import { reactLocalStorage } from 'reactjs-localstorage';
import icon1 from "../../images/icons/Group 69820.png";
import icon2 from "../../images/icons/Book Library.png";
import icon3 from "../../images/icons/Receipt.png";
import icon4 from "../../images/icons/Share.png";
import icon5 from "../../images/icons/Swatchbook.png";
import icon6 from "../../images/icons/Union.png";
import icon7 from "../../images/icons/Megaphone.png";
import icon8 from "../../images/icons/Calendar - Event.png";
import icon9 from "../../images/icons/Book Library.png";
import icon10 from "../../images/icons/Receipt.png";
import icon11 from "../../images/icons/Share.png";
import icon12 from "../../images/icons/Swatchbook.png";
import icon13 from "../../images/icons/Union.png";
import icon14 from "../../images/icons/Megaphone.png";
import icon15 from "../../images/icons/Swatchbook.png";
import { useDispatch, useSelector } from "react-redux";
import { authAction } from "../../Reducer/authReducer";
import { Link } from "react-router-dom";

export default function Header() {
  //    const [sideBarMenu,setSideBarMenu]=useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const auth = useSelector(state => state.auth)
  //   console.log("HeaderAuth", auth);
  const sidebarToggle = () => {
    let check = document.getElementById("togglesidebar").style.display;
    console.log("dis", check);
    if (check === "block") {
      document.getElementById("togglesidebar").style.display = "none";
    } else {
      document.getElementById("togglesidebar").style.display = "block";
    }
  };

  const userLogout = () => {
    if (window.confirm("Are you sure you want to logout?")) {
      reactLocalStorage.clear();
      dispatch(authAction.logOut())
      navigate("/login")
    }
  }
  return (
    <>
      <header className="DasboardHeader" id="header-section">
        <div className="container-fluid">
          <div className="row">
            <nav className="navbar navbar-expand-lg navbar-light nav-header">
              <div className="logoHam">
                <a href="#" className="MobileMenuBar" onClick={sidebarToggle}>
                  <i className="fa-solid fa-bars"></i>
                </a>
                <Link className="navbar-brand" to={"/"}>
                  <img src={logo} />
                </Link>
              </div>
              {/* <button
                                className="navbar-toggler"
                                type="button"
                                data-toggle="collapse"
                                data-target="#navbarSupportedContent"
                                aria-controls="navbarSupportedContent"
                                aria-expanded="false"
                                aria-label="Toggle navigation"
                            >
                                <span className="navbar-toggler-icon" />
                            </button> */}
              <div
                className="collapse navbar-collapse"
                id="navbarSupportedContent"
              >
                <form className="form-inline my-2 my-lg-0 ml-auto">
                  <Link
                    to={'/create-vendor'}
                    className="btn  my-2 my-sm-0 create_ShopBTn"
                  >
                    Create Vendor
                  </Link>
                </form>
                {/*  onClick={() => navigate("/")} */}
                {/* <a className='profileImg'><img src={profile} /></a> */}
                <div className="dropdown">
                  <button
                    className="btn profileImg dropdown_BTN "
                    type="button"
                    id="dropdownMenu2"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <img src={profile} />
                  </button>
                  <div
                    className="dropdown-menu dropdown-menu-right"
                    aria-labelledby="dropdownMenu2"
                  >
                    <div className="dropdown_menuInner">
                      <h5>Admin</h5>
                      <span className="emailId">{auth.user.email}</span>
                    </div>
                    {/* <button className="dropdown-item" type="button">
                      Profile
                    </button> */}
                    <button onClick={userLogout} className="dropdown-item" type="button">
                      Logout
                    </button>
                  </div>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </header>
    </>
  );
}
