import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import SearchableDropdown from "../../Component/SearchableDropdown/SearchableDropdown";
import { fileUplode, requestData } from "../../Utils/HttpClient";
import { toast } from "react-toastify";
import { ColorRing } from "react-loader-spinner";
import Layout from "../../Layout/DashboardLayout/Index";

export default function CreateProduct() {
  const [vendor, setVendor] = useState("");
  const [productType, setProductType] = useState("");
  const [category, setCategory] = useState("");
  const [age, setAge] = useState("");
  const [subCategory, setSubCategory] = useState("");
  const [product, setProduct] = useState("");
  const [image, setImage] = useState("");
  const [uploadImage, setUploadImage] = useState("");
  const [weight, setWeight] = useState("");
  const [dimention, setDimention] = useState("");
  const [price, setPrice] = useState("");
  const [discount, setDiscount] = useState("");
  const [shortDesc, setShortDesc] = useState("");
  const [stock, setStock] = useState("");
  const [multiImage, setMultiImage] = useState([]);
  const [uploadMultiImage, setUploadMultiImage] = useState([]);
  const [uploadMultiImages, setUploadMultiImages] = useState([]);
  const [hotDeal, setHotDeal] = useState("N");
  const [recomented, setRecomented] = useState("N");
  const [parentChoice, setParentChoice] = useState("N");
  const [favorite, setFavorite] = useState("N");
  const [page, setPage] = useState("");
  const [metaDesc, setMetaDesc] = useState("");
  const [tags, setTags] = useState("");
  const [condition, setCondition] = useState("");
  const [publisher, setPublisher] = useState("");
  const [author, setAuthor] = useState("");
  const [language, setLanguage] = useState("");
  const [proSku, setProSku] = useState("");
  const [longDesc, setLongDesc] = useState("");
  const [color, setColor] = useState("");
  const [brand, setBrand] = useState("");

  // For Select Option
  const [getAllVendor, setGetAllVendor] = useState([]);
  const [getAllType, setGetAllType] = useState([]);
  const [getAllCategory, setGetAllCategory] = useState([]);
  const [getAllAge, setGetAllAge] = useState([]);
  const [getAllSubCategory, setGetAllSubCategory] = useState([]);
  const [getAllColor, setGetAllColor] = useState([]);
  const [getAllBrand, setGetAllBrand] = useState([]);

  const [filterMultiImage, setFilterMultiImage] = useState([])

  const navigate = useNavigate();

  useEffect(() => {
    fetchVendor();
  }, []);

  const fetchVendor = async () => {
    let result = await requestData("admin/fetch_vendor", "POST");
    if (result && result.data) {
      //   setGetAllVendor(result.data);
      // console.log("Vendor", result.data);
      let vendorArr = [];
      result.data.forEach((item) => {
        let vendorObj = {
          id: item.id,
          name: item.name,
        };
        vendorArr.push(vendorObj);
      });
      setGetAllVendor(vendorArr);
    }
    fetchType();
    fetchAge();
    fetchColor();
    fetchBrand();
  };

  const fetchType = async () => {
    let result = await requestData("admin/fetch_all_type", "POST");
    if (result && result.data) {
      setGetAllType(result.data);
      //   console.log("ProductType", result.data);
    }
  };

  const getCategoryByType = async (id) => {
    if (id !== "") {
      let dataSend = {
        type: id,
      };
      let result = await requestData(
        "admin/fetch_Allcategory",
        "POST",
        dataSend
      );
      if (result && result.status) {
        // console.log("Category", result.data);
        setGetAllCategory(result.data);
      }
    }
  };

  const fetchAge = async () => {
    let result = await requestData("admin/fetchallage", "POST");
    // console.log(result.data)
    if (result && result.data) {
      setGetAllAge(result.data);
      // console.log(result.data);
    }
  };

  const getSubCategoryByAgeCategory = async (cat, age) => {
    if (cat !== "" && age !== "") {
      let dataSend = {
        category_id: cat,
        age_id: age,
      };
      // console.log("SendData", dataSend);
      let res = await requestData(
        "admin/fetch_AllsubCategory",
        "POST",
        dataSend
      );
      if (res && res.status) {
        setGetAllSubCategory(res.data);
        // console.log("SubCat", res.data);
      }
    }
  };

  const fetchColor = async () => {
    let result = await requestData("admin/fetch_color", "POST");
    // console.log(result.data)
    if (result && result.data) {
      setGetAllColor(result.data);
    }
  };

  const fetchBrand = async () => {
    let result = await requestData("admin/fetch_brand", "POST");
    // console.log(result.data)
    if (result && result.data) {
      setGetAllBrand(result.data);
    }
  };

  const uploadImageURL = async (e) => {
    let data = new FormData();
    data.append("image", e.target.files[0]);
    let result = await fileUplode("admin/image_upload", "POST", data);
    // console.log(result);
    if (result && result.status) {
      // console.log(result);
      setImage(result.data);
    }
    setUploadImage(URL.createObjectURL(e.target.files[0]));
  };

  const deleteImageURL = () => {
    setImage("")
    setUploadImage("")
  }

  const calculateSellingPrice = () => {
    if (price && discount) {
      return price - discount;
    }
  };

  const onImageUpload = async (e) => {
    if (e.target.files) {
      const imageArr = Array.from(e.target.files);
      const filesArray = Array.from(e.target.files).map((file) =>
        URL.createObjectURL(file)
      );
      setUploadMultiImage((prevImages) => prevImages.concat(filesArray));
      imageUploadApi(imageArr);
      setFilterMultiImage(imageArr)
    }
  };

  const setImagesUrl = (url) => {
    let tempUrlArr = multiImage;
    tempUrlArr.push(url);
    setMultiImage(tempUrlArr);
    // console.log(tempUrlArr);
  };

  const imageUploadApi = async (files) => {
    //need to start loader
    for (const file of files) {
      let data = new FormData();
      data.append("image", file);
      let result = await fileUplode("admin/image_upload", "POST", data);

      if (result && result.status) {
        // console.log("MultiImage...", result);
        setImagesUrl(result.data);
      }
    }
  };

  const deleteMultiImageUrl = (count) => {
    // console.log(uploadMultiImage)
    const delFile = uploadMultiImage.filter((item, index) => index !== count)
    console.log("Count", count);
    setUploadMultiImage(delFile)
    const delFileImage = multiImage.filter((item, index) => index !== count)
    setMultiImage(delFileImage)
    // setUploadMultiImage([])
    // const updatedArr = delFile.map((file) => URL.createObjectURL
    // (file))
    // setUploadMultiImage((prevImages) => prevImages.concat(updatedArr));
  }

  const handleOnChangeParent = (e) => {
    if (e.target.checked) {
      setParentChoice("Y");
    } else {
      setParentChoice("N");
    }
  };

  const handleOnChangeRecomend = (e) => {
    if (e.target.checked) {
      setRecomented("Y");
    } else {
      setRecomented("N");
    }
  };

  const handleOnChangeHotDeal = (e) => {
    if (e.target.checked) {
      setHotDeal("Y");
    } else {
      setHotDeal("N");
    }
  };

  const handleOnChangeFavorite = (e) => {
    if (e.target.checked) {
      setFavorite("Y");
    } else {
      setFavorite("N");
    }
  };

  const submitProduct = async (e) => {
    e.preventDefault();
    if (
      (vendor !== "",
        productType !== "",
        category !== "",
        age !== "",
        subCategory !== "",
        product !== "",
        image !== "",
        weight !== "",
        dimention !== "",
        price !== "",
        discount !== "",
        shortDesc !== "",
        stock !== "",
        multiImage.length > 0)
    ) {
      let dataSend = {
        vandor_id: vendor,
        product_type_id: productType,
        Product_title: product,
        main_img: image,
        weight: weight,
        age: age,
        Dimentions: dimention,
        price: price,
        discount: discount,
        selling_price: (price - discount).toString(),
        category: category,
        subcategory: subCategory,
        short_des: shortDesc,
        number_of_pages: page,
        conditions: condition,
        product_sku: proSku,
        language: language,
        publishers: publisher,
        author: author,
        long_des: longDesc,
        meta_des: metaDesc,
        tags: tags,
        Brand: brand,
        colour: color,
        stock: stock,
        image: multiImage,
        parent_status: parentChoice,
        recomd_status: recomented,
        hot_deals: hotDeal,
        fav_status: favorite,
      };
      console.log(dataSend);
      // console.log("SendData...", dataSend);
      // console.log("dataSend", dataSend);
      let result = await requestData("admin/add_product", "POST", dataSend);
      if (result && result.status) {
        // console.log(result)
        toast("Product created Successfully!", {
          position: "bottom-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setTimeout(function () {
          navigate("/Products");
        }, 2000);
      } else {
        toast("Product already exists! Try a new one...", {
          position: "bottom-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } else {
      toast("All Fields are Required", {
        position: "bottom-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  // const handleCallback = (childData) => {
  //   setVendor(childData);
  //   console.log("ChildData", childData);
  // };

  //   const data = ["abc", "abd", "shiv"];
  return (
    <Layout sidebar>
      {/* {console.log("AllVendor", getAllVendor)} */}
      <main className="enrollment">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-4 col-12">
              <div className="description">
                <h4 className="text-base mb-2">Description</h4>
                <p className="text-body ">
                  Add your Product details and necessary information here
                </p>
              </div>
            </div>

            <div className="col-xl-8 col-lg-8 col-md-8 col-12 createCategoryCustom">
              <div className="form-group">
                <label>Vendor</label>
                {/* <SearchableDropdown
                  data={getAllVendor}
                  parentCallback={handleCallback}
                /> */}
                <div className="row">
                  <div className="col-md-12">
                    <select
                      className="form-control"
                      id="exampleFormControlSelect1"
                      value={vendor}
                      onChange={(e) => setVendor(e.target.value)}
                    >
                      <option value="">Select Vendor</option>
                      {getAllVendor ? (
                        getAllVendor.map((item, index) => {
                          return (
                            <option value={item.id} key={index}>
                              {item.name}
                            </option>
                          );
                        })
                      ) : (
                        <span
                          style={{
                            display: "flex",
                            position: "fixed",
                            zIndex: "1000",
                            left: "0",
                            top: "0",
                            right: "0",
                            margin: "0 auto",
                            background: "rgba(0,0,0,0.3)",
                            justifyContent: "center",
                            height: "100vh",
                            alignItems: "center",
                          }}
                        >
                          <ColorRing
                            visible={true}
                            id="colorRing"
                            height="80"
                            width="80"
                            ariaLabel="blocks-loading"
                            wrapperStyle={{}}
                            wrapperclassName="blocks-wrapper"
                            colors={[
                              "#f21322",
                              "#0cb3f0",
                              "#f58716",
                              "#f00cd9",
                              "#d50cf0",
                            ]}
                          />
                        </span>
                      )}
                    </select>
                  </div>
                </div>
              </div>

              <div className="form-group">
                <label>Product Type</label>
                <div className="row">
                  <div className="col-md-12">
                    <select
                      className="form-control"
                      id="exampleFormControlSelect1"
                      value={productType}
                      onChange={(e) => {
                        setProductType(e.target.value);
                        getCategoryByType(e.target.value);
                      }}
                    >
                      <option value="">Select Product Type</option>
                      {getAllType.map((item, index) => {
                        return (
                          <option value={item.id} key={index}>
                            {item.producttype}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label>Category</label>
                <div className="row">
                  <div className="col-md-12">
                    <select
                      className="form-control"
                      id="exampleFormControlSelect1"
                      value={category}
                      onChange={(e) => setCategory(e.target.value)}
                    >
                      <option value="">Select Category</option>
                      {getAllCategory.map((item, index) => {
                        return (
                          <option value={item.id} key={index}>
                            {item.Category_name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label>Age</label>
                <div className="row">
                  <div className="col-md-12">
                    <select
                      className="form-control"
                      id="exampleFormControlSelect1"
                      value={age}
                      onChange={(e) => {
                        setAge(e.target.value);
                        getSubCategoryByAgeCategory(category, e.target.value);
                      }}
                    >
                      <option value="">Select Age</option>
                      {getAllAge.map((item, index) => {
                        return (
                          <option value={item.id} key={index}>
                            {item.age_duration}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label>SubCategory</label>
                <div className="row">
                  <div className="col-md-12">
                    <select
                      className="form-control"
                      id="exampleFormControlSelect1"
                      value={subCategory}
                      onChange={(e) => setSubCategory(e.target.value)}
                    >
                      <option value="">Select SubCategory</option>
                      {getAllSubCategory.map((item, index) => {
                        return (
                          <option value={item.id} key={index}>
                            {item.subcategory_name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label>Product Name</label>
                <input
                  type="text"
                  placeholder="Enter Product Name"
                  className="form-control"
                  style={{ width: "100%" }}
                  value={product}
                  onChange={(e) => setProduct(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label>Main Image</label>
                <input
                  type="file"
                  id="myfile"
                  name="myfile"
                  className="form-control"
                  style={{ width: "100%" }}
                  onChange={uploadImageURL}
                />
                <div className="col-md-12 my-2">
                  {uploadImage !== "" ? (
                    <span style={{ position: "relative" }}>
                      <img src={uploadImage} height="100px" widtth="100px" />
                      <i
                        className="fa-solid fa-xmark"
                        style={{
                          position: "absolute",
                          right: "0px",
                          background: "#f90000",
                          padding: "7px",
                          borderRadius: "50%",
                          fontSize: "11px",
                          color: "#fff",
                          cursor: "pointer",
                        }}
                        onClick={deleteImageURL}
                      ></i>
                    </span>
                  ) : null}
                </div>
              </div>
              <div className="form-group">
                <label>Weight (In Kgs)</label>
                <input
                  type="text"
                  placeholder="Enter Weight"
                  className="form-control"
                  style={{ width: "100%" }}
                  value={weight}
                  onChange={(e) => setWeight(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label>Dimention (In Cms)</label>
                <input
                  type="text"
                  placeholder="Enter Dimention in (--x--x--) format"
                  className="form-control"
                  style={{ width: "100%" }}
                  value={dimention}
                  onChange={(e) => setDimention(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label>Price</label>
                <input
                  type="number"
                  placeholder="Enter Price"
                  className="form-control"
                  style={{ width: "100%" }}
                  value={price}
                  onChange={(e) => setPrice(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label>Discount</label>
                <input
                  type="number"
                  placeholder="Enter Discount Price"
                  className="form-control"
                  style={{ width: "100%" }}
                  value={discount}
                  onChange={(e) => setDiscount(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label>Selling Price</label>
                <input
                  type="number"
                  placeholder="Enter Selling Price"
                  className="form-control"
                  style={{ width: "100%" }}
                  value={calculateSellingPrice() || ""}
                  disabled
                />
              </div>
              <div className="form-group">
                <label>Short Description</label>
                <textarea
                  id="w3review"
                  name="w3review"
                  rows={4}
                  cols={50}
                  style={{ width: "100%" }}
                  value={shortDesc}
                  onChange={(e) => setShortDesc(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label>Stock</label>
                <input
                  type="text"
                  placeholder="Enter Stock"
                  className="form-control"
                  style={{ width: "100%" }}
                  value={stock}
                  onChange={(e) => setStock(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label>Multi Image</label>
                <input
                  type="file"
                  id="myfile"
                  name="myfile"
                  className="form-control"
                  style={{ width: "100%" }}
                  onChange={onImageUpload}
                />
              </div>
              <div className="col-md-12 my-2">
                {/* {console.log("uploadMul", uploadMultiImages)} */}
                {uploadMultiImage !== ""
                  ? uploadMultiImage.map((item, index) => (
                    <span style={{ position: "relative" }}>
                      <img
                        key={index}
                        src={item}
                        height="100px"
                        widtth="100px"
                        className="mx-3"
                      />
                      <i
                        className="fa-solid fa-xmark"
                        style={{
                          position: "absolute",
                          right: "0px",
                          background: "#f90000",
                          padding: "7px",
                          borderRadius: "50%",
                          fontSize: "11px",
                          color: "#fff",
                          cursor: "pointer",
                        }}
                        onClick={() => deleteMultiImageUrl(index)}
                      ></i>
                    </span>
                  ))
                  : null}
              </div>
              <div className="productCheckboxWrap">
                <div className="form-group form-check">
                  <input
                    type="checkbox"
                    className="form-check-input "
                    id="exampleCheck1"
                    onChange={handleOnChangeParent}
                  />
                  <label className="form-check-label" htmlFor="exampleCheck1">
                    Parent Choice
                  </label>
                </div>
                <div className="form-group form-check">
                  <input
                    type="checkbox"
                    className="form-check-input "
                    id="exampleCheck1"
                    onChange={handleOnChangeRecomend}
                  />
                  <label className="form-check-label" htmlFor="exampleCheck1">
                    Recomended
                  </label>
                </div>
                <div className="form-group form-check">
                  <input
                    type="checkbox"
                    className="form-check-input l"
                    id="exampleCheck1"
                    onChange={handleOnChangeHotDeal}
                  />
                  <label className="form-check-label" htmlFor="exampleCheck1">
                    Hot Deals
                  </label>
                </div>
                <div className="form-group form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="exampleCheck1"
                    onChange={handleOnChangeFavorite}
                  />
                  <label className="form-check-label" htmlFor="exampleCheck1">
                    Favourite
                  </label>
                </div>
                {productType === "1" && (
                  <div>
                    <div className="form-group mb-4">
                      <label htmlFor="longDesc">Long Description</label>
                      <div className="row">
                        <div className="col-md-12">
                          <textarea
                            id="londDesc"
                            name="londDesc"
                            rows="4"
                            cols="60"
                            onChange={(e) => setLongDesc(e.target.value)}
                            value={longDesc}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group mb-4">
                      <label htmlFor="metaDesc">Meta Description</label>
                      <div className="row">
                        <div className="col-md-12">
                          <textarea
                            id="metaDesc"
                            name="metaDesc"
                            rows="4"
                            cols="60"
                            onChange={(e) => setMetaDesc(e.target.value)}
                            value={metaDesc}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group mb-4">
                      <label>Tags</label>
                      <div className="row">
                        <div className="col-md-12">
                          <input
                            type="text"
                            placeholder="Enter Category Name"
                            className="form-control"
                            onChange={(e) => setTags(e.target.value)}
                            value={tags}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group mb-4">
                      <label>Number of Pages</label>
                      <div className="row">
                        <div className="col-md-12">
                          <input
                            type="text"
                            placeholder="Enter Page No"
                            className="form-control"
                            onChange={(e) => setPage(e.target.value)}
                            value={page}
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group mb-4">
                      <label>Author</label>
                      <div className="row">
                        <div className="col-md-12">
                          <input
                            type="text"
                            placeholder="Enter Author Name"
                            className="form-control"
                            onChange={(e) => setAuthor(e.target.value)}
                            value={author}
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group mb-4">
                      <label>Publisher</label>
                      <div className="row">
                        <div className="col-md-12">
                          <input
                            type="text"
                            placeholder="Enter Publisher Name"
                            className="form-control"
                            onChange={(e) => setPublisher(e.target.value)}
                            value={publisher}
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group mb-4">
                      <label>Condition</label>
                      <div className="row">
                        <div className="col-md-12">
                          <input
                            type="text"
                            placeholder="Enter Condition"
                            className="form-control"
                            onChange={(e) => setCondition(e.target.value)}
                            value={condition}
                            required
                          />
                        </div>
                      </div>
                    </div>

                    <div className="form-group mb-4">
                      <label>Product Sku</label>
                      <div className="row">
                        <div className="col-md-12">
                          <input
                            type="text"
                            placeholder="Enter Product Sku"
                            className="form-control"
                            onChange={(e) => setProSku(e.target.value)}
                            value={proSku}
                            required
                          />
                        </div>
                      </div>
                    </div>

                    <div className="form-group mb-4">
                      <label>Language</label>
                      <div className="row">
                        <div className="col-md-12">
                          <input
                            type="text"
                            placeholder="Enter Language"
                            className="form-control"
                            onChange={(e) => setLanguage(e.target.value)}
                            value={language}
                            required
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {productType === "2" && (
                  <>
                    <div className="form-group mb-4" style={{ width: "100%" }}>
                      <label>Color</label>
                      <div className="row">
                        <div className="col-md-12">
                          <select
                            className="form-control"
                            id="exampleFormControlSelect1"
                            value={color}
                            onChange={(e) => setColor([e.target.value])}
                            required
                          >
                            <option value="">Select Color</option>
                            {getAllColor.map((item, index) => {
                              return (
                                <option value={item.id} key={index}>
                                  {item.color_name}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                    </div>
                  </>
                )}

                {productType === "3" && (
                  <>
                    <div className="form-group mb-4" style={{ width: "100%" }}>
                      <label>Color</label>
                      <div className="row">
                        <div className="col-md-12">
                          <select
                            className="form-control"
                            id="exampleFormControlSelect1"
                            value={color}
                            onChange={(e) => {
                              setColor([e.target.value]);
                            }}
                            required
                          >
                            <option value="">Select Color</option>
                            {getAllColor.map((item, index) => {
                              return (
                                <option value={item.id} key={index}>
                                  {item.color_name}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                      {/* </div> */}
                    </div>
                    <div className="form-group mb-4" style={{ width: "100%" }}>
                      <label>Brand</label>
                      <div className="row">
                        <div className="col-md-12">
                          <select
                            className="form-control"
                            id="exampleFormControlSelect1"
                            value={brand}
                            onChange={(e) => setBrand(e.target.value)}
                            required
                          >
                            <option value="">Select Brand</option>
                            {getAllBrand.map((item, index) => {
                              return (
                                <option value={item.id} key={index}>
                                  {item.brand_name}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>


            </div>
          </div>

          <div className="row mt-5">
            <div className="col-lg-8 col-md-8 col-12" style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
              <div className="form-group custProduct">
                <Link to={"/Products"} className="btn btn1">
                  Go Back
                </Link>
              </div>
            </div>

            <div className="col-lg-4 col-md-4 col-12">

              <div className="form-group custProduct">
                <button
                  onClick={submitProduct}
                  type="button"
                  className="btn btn2"
                >
                  Add Product
                </button>
              </div>
            </div>

          </div>

          {/* <div className="row mt-5">
            <div className="col-xl-9 col-lg-9 col-12 col-md-9"></div>
            <div className="col-xl-3 col-lg-3 col-12 col-md-3">
              <a href="#" className="btn btn-addCategory ">
                Add Product
              </a>
            </div>
          </div> */}
        </div>
      </main>
    </Layout>
  );
}
