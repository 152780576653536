import React, {useEffect, useState} from 'react'
import profile from "../../images/avatar-placeholder.svg";
import PaginatedItems from "../../Component/Pagination/Index";
import Layout from '../../Layout/DashboardLayout/Index';
import { PROFILE_IMG_URL, requestData } from '../../Utils/HttpClient';
import { toast } from "react-toastify";
import { ColorRing } from "react-loader-spinner";
import Pagination from "../../Component/Pagination/Pagination";

export default function User() {

  const [fetchUser, setFetchUser] = useState([])
  const [status, setStatus] = useState("")
  const [postPerPage, setPostPerPage] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const [userId, setUserId] = useState("")

  useEffect(() => {
    getAllUser();
  }, []);

  const lastPostIndex = currentPage * postPerPage
  const firstPostIndex = lastPostIndex - postPerPage
  const currentPost = fetchUser.slice(firstPostIndex, lastPostIndex)

  const getAllUser = async () => {
    let data = await requestData("admin/fetchallactiveuser", "POST");
    // console.log("GetColor", data.data);
    // return false
    if (data && data.status) {
      setFetchUser(data.data);
      console.log("AllUser", data);
    }
  };

const handleStatus = async(id, status) => {
  console.log(id);
  let dataSend = {
    user_id: id,
    user_status: status
  }
  console.log("Status", dataSend);
  let data = await requestData("admin/deactiveuser", "POST", dataSend);
  if(data && data.status) {
    toast("User deactivate Successfully!", {
      position: "bottom-right",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    getAllUser()
  }
  else{
    toast("Something went wrong!", {
      position: "bottom-right",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
}

  return (
    <Layout sidebar>
      <main className="enrollment">
        <div className='container-fluid'>
          {/* <div className="col-md-12 col-12 col-xl-12 col-lg-12 px-0" style={{ marginBottom: "20px" }}>
            <div className="categoryBox custCategoryWrap" style={{ display: "flex" }} >
              <div className="categoryLeft">
                <h1 className="text-heading">Customers</h1>
              </div>
              <div className="formBtn">
                <div className="form-group has-search">
                  <span className="fa fa-search form-control-feedback" />
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Type your query and press enter"
                  />
                </div>
                <a href="#" className="btn btn-addCategory">
                  + Add
                </a>
              </div>
            </div>
          </div> */}

          <div className="users">
            <div className="card recentTable_Card">
              <div className="card-body p-0">
                <div className="table-responsive">
                  <table className="table table-hover mb-0">
                    <thead>
                      <tr>
                        <th className="text-left">Avatar</th>
                        <th className="text-left">Name</th>
                        <th className="text-left">Email</th>
                        <th>Phone No</th>
                        {/* <th>Available wallet points</th> */}
                        <th>Status</th>
                        {/* <th className='text-right'>Actions</th> */}
                      </tr>
                    </thead>
                    {currentPost.length > 0 ? (currentPost.map((item, index) => (
                    <tbody key={index}>
                      <tr>
                        <td className="text-left">
                          {item.user_image ? 
                          <span>
                            <img
                              src={`${PROFILE_IMG_URL}${item.user_image}`}
                              className="avator_Image"
                            />
                          </span> :
                          <span>
                          <img
                            src={profile}
                            className="avator_Image"
                          />
                        </span>
                          }
                        </td>
                        <td className="text-left">{item.name}</td>
                        <td className="text-left">{item.email}</td>
                        <td>{item.phone}</td>
                        {/* <td>5000</td> */}
                        <td>
                          <select 
                          className='selectMenu' 
                          value={item.status} 
                          onChange={(e) => {
                            // StatusChange(e.target.value)
                            // setStatus(e.target.value)
                            console.log(e.target.value);
                            handleStatus(item.id, e.target.value)
                          }}
                          >
                            <option value="Y">Active</option>
                            <option value="N">Inactive</option>
                          </select>
                        </td>
                        <td></td>
                      </tr>
          
                    </tbody>

                    ))): <span
                    style={{
                      display: "flex",
                      position: "fixed",
                      zIndex: "1000",
                      left: "0",
                      top: "0",
                      right: "0",
                      margin: "0 auto",
                      background: "rgba(0,0,0,0.3)",
                      justifyContent: "center",
                      height: "100vh",
                      alignItems: "center",
                    }}
                  >
                    <ColorRing
                      visible={true}
                      id="colorRing"
                      height="80"
                      width="80"
                      ariaLabel="blocks-loading"
                      wrapperStyle={{}}
                      wrapperclassName="blocks-wrapper"
                      colors={[
                        '#f21322', '#0cb3f0', '#f58716', '#f00cd9', '#d50cf0'
                      ]}
                    />
                  </span>}
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Pagination
          totalPosts={fetchUser.length}
          postPerPage={postPerPage}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
        />
        {/* <PaginatedItems itemsPerPage={4}/> */}
      </main>
    </Layout>
  )
}
