import React from 'react'


export default function Index() {
  return (
    <>
      <main className="enrollment profilePage">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-md-12 col-lg-12 col-xl-12">
              <div className="border-border-base py-4">
                <h1 className="text-lg font-semibold text-heading">
                  Profile Settings
                </h1>
              </div>
            </div>
          </div>
          <div
            className="row border-border-base"
            style={{ paddingTop: "3rem", paddingBottom: "3rem" }}
          >
            <div className="col-xl-4 col-lg-4 col-md-4 col-12">
              <div classname="description">
                <h4 className="text-base mb-2">Information</h4>
                <p className="text-body">Add your profile information from here</p>
              </div>
            </div>
            <div className="col-xl-8 col-lg-8 col-md-8 col-12">
              <div
                className=""
                style={{ padding: 32, background: "#fff", borderRadius: 5 }}
              >
                <div className="mb-3">
                  <label
                    htmlFor="name"
                    className="block text-body-dark font-semibold text-sm leading-none mb-3"
                  >
                    Name
                  </label>
                  <input
                    style={{ width: "100%", height: 50 }}
                    id="name"
                    name="name"
                    type="text"
                    className="px-4 h-12"
                    autoComplete="off"
                    autoCorrect="off"
                    autoCapitalize="off"
                    spellCheck="false"
                    aria-invalid="false"
                  />
                </div>
                <div className="mb-3">
                  <label className="block text-body-dark font-semibold text-sm leading-none mb-3">
                    Bio
                  </label>
                  <textarea
                    style={{ width: "100%", height: 100, resize: "none" }}
                    id="profile.bio"
                    name="profile.bio"
                    className="py-3 px-4 w-full rounded  "
                    autoComplete="off"
                    autoCorrect="off"
                    autoCapitalize="off"
                    spellCheck="false"
                    rows={4}
                    defaultValue={""}
                  />
                </div>
                <div className="mb-3">
                  <label
                    htmlFor="profile.contact"
                    className="block text-body-dark font-semibold text-sm leading-none mb-3"
                  >
                    Contact Number
                  </label>
                  <input
                    style={{ width: "100%", height: 50 }}
                    id="profile.contact"
                    name="profile.contact"
                    type="text"
                    className="px-4 h-12  "
                    autoComplete="off"
                    autoCorrect="off"
                    autoCapitalize="off"
                    spellCheck="false"
                    aria-invalid="false"
                  />
                </div>
              </div>
            </div>
            <div className="col-xl-10 col-lg-10 col-12 col-md-10"></div>
            <div className="col-xl-2 col-lg-2 col-12 col-md-2">
              <a href="#" className="btn btn-addCategory" style={{ marginTop: 30 }}>
                Save
              </a>
            </div>
          </div>
          <div
            className="row border-border-base"
            style={{ paddingTop: "3rem", paddingBottom: "3rem" }}
          >
            <div className="col-xl-4 col-lg-4 col-md-4 col-12">
              <div classname="description">
                <h4 className="text-base mb-2">Password</h4>
                <p className="text-body">Change your password from here</p>
              </div>
            </div>
            <div className="col-xl-8 col-lg-8 col-md-8 col-12">
              <div
                className=""
                style={{ padding: 32, background: "#fff", borderRadius: 5 }}
              >
                <div className="mb-3">
                  <label
                    htmlFor="name"
                    className="block text-body-dark font-semibold text-sm leading-none mb-3"
                  >
                   Old Password
                  </label>
                  <input
                    style={{ width: "100%", height: 50 }}
                    id="name"
                    name="name"
                    type="password"
                    className="px-4 h-12  "
                    autoComplete="off"
                    autoCorrect="off"
                    autoCapitalize="off"
                    spellCheck="false"
                    aria-invalid="false"
                  />
                </div>
                <div className="mb-3">
                  <label className="block text-body-dark font-semibold text-sm leading-none mb-3">
                    New Password
                  </label>
                  <input
                    style={{ width: "100%", height: 50 }}
                    id="name"
                    name="name"
                    type="password"
                    className="px-4 h-12  "
                    autoComplete="off"
                    autoCorrect="off"
                    autoCapitalize="off"
                    spellCheck="false"
                    aria-invalid="false"
                  />
                </div>
                <div className="mb-3">
                  <label
                    htmlFor="profile.contact"
                    className="block text-body-dark font-semibold text-sm leading-none mb-3"
                  >
                    Confirm Password
                  </label>
                  <input
                    style={{ width: "100%", height: 50 }}
                    id="profile.contact"
                    name="profile.contact"
                    type="password"
                    className="px-4 h-12  "
                    autoComplete="off"
                    autoCorrect="off"
                    autoCapitalize="off"
                    spellCheck="false"
                    aria-invalid="false"
                  />
                </div>
              </div>
            </div>
            <div className="col-xl-10 col-lg-10 col-12 col-md-10"></div>
            <div className="col-xl-2 col-lg-2 col-12 col-md-2">
              <a href="#" className="btn btn-addCategory" style={{ marginTop: 30 }}>
                Save
              </a>
            </div>
          </div>
        </div>
      

      </main>

    </>
  )
}
