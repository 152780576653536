import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { requestData } from "../../Utils/HttpClient";
import { toast } from "react-toastify";
import Layout from "../../Layout/DashboardLayout/Index";
import { Link } from "react-router-dom";
// import SearchableDropdown from '../../Component/SearchableDropdown/SearchableDropdown'

export default function CreateCategory() {
  const [category, setCategory] = useState("");
  const [age, setAge] = useState("");
  const [subCategory, setSubCategory] = useState("");

  const [getCategory, setGetCategory] = useState([]);
  const [getAge, setGetAge] = useState([]);

  const navigate = useNavigate();
  // const data = ["abc", "abd", "shiv"]

  useEffect(() => {
    getAllCategory();
  }, []);

  const getAllCategory = async () => {
    let data = await requestData("admin/fetch_category", "POST");
    if (data && data.status) {
      setGetCategory(data.data);
    }
    getAllAge();
  };

  const getAllAge = async () => {
    let data = await requestData("admin/fetchallage", "POST");
    if (data && data.status) {
      setGetAge(data.data);
    }
  };

  const submitSubCategory = async () => {
    //   alert();
    if (subCategory !== "" && category !== "" && age !== "") {
      let dataSend = {
        subcategory_name: subCategory,
        Category_id: category,
        age_id: age,
      };
      // console.log(dataSend)
      // return false
      let result = await requestData("admin/add_subCategory", "POST", dataSend);
      // console.log(result)
      if (result && result.status) {
        toast("SubCategory created Successfully!", {
          position: "bottom-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setTimeout(function () {
          navigate("/subcategory");
        }, 2000);
      } else {
        toast("SubCategory already exists! Try a new one...", {
          position: "bottom-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } else {
      toast("All Fields are Required", {
        position: "bottom-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  return (
    <Layout sidebar>
      <main className="enrollment">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-4 col-12">
              <div className="description">
                <h4 className="text-base mb-2">Description</h4>
                <p className="text-body ">
                  Add your subcategory details and necessary information
                  here
                </p>
              </div>
            </div>
            <div className="col-xl-8 col-lg-8 col-md-8 col-12 createCategoryCustom">
              <div className="form-group">
                <label>Category</label>
                {/* <SearchableDropdown data={data} /> */}
                <div className="row">
                  <div className="col-md-12">
                    <select
                      className="form-control"
                      id="exampleFormControlSelect1"
                      value={category}
                      onChange={(e) => setCategory(e.target.value)}
                    >
                      <option value="">Select Category</option>
                      {getCategory.map((item, index) => {
                        return (
                          <option value={item.id} key={index}>
                            {item.Category_name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label>Age</label>
                {/* <SearchableDropdown data={data} /> */}
                <div className="row">
                  <div className="col-md-12">
                    <select
                      className="form-control"
                      id="exampleFormControlSelect1"
                      value={age}
                      onChange={(e) => setAge(e.target.value)}
                    >
                      <option value="">Select Age</option>
                      {getAge.map((item, index) => {
                        return (
                          <option value={item.id} key={index}>
                            {item.age_duration}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label>SubCategory</label>
                <input
                  type="text"
                  placeholder="Enter SubCategory Name"
                  style={{ width: "100%" }}
                  value={subCategory}
                  onChange={(e) => setSubCategory(e.target.value)}
                />
              </div>
            </div>
          </div>

          <div className="row mt-5">
            <div className="col-xl-8 col-lg-8 col-12 col-md-9" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
              <Link to={"/subcategory"}>
                <button type="button" href="#" className="btn btn-addCategory ">
                  Back
                </button>
              </Link>
            </div>
            <div className="col-xl-4 col-lg-4 col-12 col-md-3">
              <button
                onClick={submitSubCategory}
                className="btn btn-addCategory "
              >
                Add SubCategory
              </button>
            </div>
          </div>
        </div>
      </main>
    </Layout>
  );
}
