import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { fileUplode, requestData, requestShiprocket } from "../../Utils/HttpClient";
import { toast } from "react-toastify";
import { IMG_URL } from "../../Utils/HttpClient";
import { ColorRing } from "react-loader-spinner";
import Layout from "../../Layout/DashboardLayout/Index";
import { reactLocalStorage } from "reactjs-localstorage";

const OrderDetail = () => {
  const [allOrderStatus, setAllOrderStatus] = useState([]);
  const [orderStatus, setOrderStatus] = useState("");
  const [productDetail, setProductDetail] = useState([]);
  const [totalPrice, setTotalPrice] = useState("");
  const [shippingAddress, setShippingAddress] = useState("");
  const [billingAddress, setBillingAddress] = useState("");
  const [changeStatus, setChangeStatus] = useState("1");
  const [trackingStatus, setTrackingStatus] = useState("");
  const [currentStatus, setCurrentStatus] = useState("")
  const [shipmentStatus, setShipmentStatus] = useState("")

  const [params] = useSearchParams();
  const invoice = params.get("invoiceId");
  const statusName = params.get("statusName");

  // console.log("StatusName...", statusName);

  const data = [
    {
      tracking_data: {
        track_status: 1,
        shipment_status: 42,
        shipment_track: [
          {
            id: 185584215,
            awb_code: "1091188857722",
            courier_company_id: 10,
            shipment_id: 168347943,
            order_id: 168807908,
            pickup_date: null,
            delivered_date: null,
            weight: "0.10",
            packages: 1,
            current_status: "PICKED UP",
            delivered_to: "Mumbai",
            destination: "Mumbai",
            consignee_name: "Musarrat",
            origin: "PALWAL",
            courier_agent_details: null,
            edd: "2021-12-27 23:23:18"
          }
        ],
        shipment_track_activities: [
          {
            date: "2021-12-23 14:23:18",
            status: "X-PPOM",
            activity: "In Transit - Shipment picked up",
            location: "Palwal_NewColony_D (Haryana)",
            "sr-status": "42"
          },
          {
            date: "2021-12-23 14:19:37",
            status: "FMPUR-101",
            activity: "Manifested - Pickup scheduled",
            location: "Palwal_NewColony_D (Haryana)",
            "sr-status": "NA"
          },
          {
            date: "2021-12-23 14:19:34",
            status: "X-UCI",
            activity: "Manifested - Consignment Manifested",
            location: "Palwal_NewColony_D (Haryana)",
            "sr-status": "5"
          }
        ],
        track_url: "https://shiprocket.co//tracking/1091188857722",
        etd: "2021-12-28 10:19:35"
      }
    }
  ]

  useEffect(() => {
    getShipment()
    authRocket()
    getProductDetailByUserId()
    // getAllOrderStatus();
  }, []);

  const authRocket = async () => {
    let data = {
      email: "Bookswali.com@gmail.com",
      password: "Book@123"
    }
    const result = await requestShiprocket('auth/login', 'POST', data)
    console.log("Rocket", result);
    reactLocalStorage.set('token', result.token)

  }

  const getShipment = async () => {
    const data = {
      invoice_id: invoice
    }
    const res = await requestData('user/fetch_order_update', 'POST', data)
    // console.log("order update", res);
    if (res.status) {
      trackOrder(res.data?.shipment_id)
    }
    console.log("Ship", res);
  }

  const trackOrder = async (shipmentId) => {
    const result = await requestShiprocket(`courier/track/shipment/${shipmentId}`, 'GET')
    console.log("track", result);
    if (result.tracking_data?.track_status === 1) {
      setAllOrderStatus(result[0].tracking_data.shipment_track_activities);
      setCurrentStatus(result[0].tracking_data.shipment_track[0].current_status)
    }
    else {
      setTrackingStatus(result.tracking_data?.error)
    }
  }

  // const getAllOrderStatus = async () => {
  //   let result = await requestData("admin/fetchorderstatus", "POST");

  //   console.log("status", result);
  //   if (result && result.data) {
  //     let reverseArr = [];
  //     // let serialArr = [];
  //     for (let item of result.data) {
  //       reverseArr.push(item);
  //     }
  //     console.log("AllOrder...", reverseArr);
  //     setAllOrderStatus(result.data);
  //     console.log("OrderStatus...", result.data);
  //     console.log("Status...", statusName);
  //     let arr = result.data.filter((id) => id.status === statusName);
  //     console.log("Arr...", arr);
  //     // setOrderStatus(arr[0]);
  //     // setChangeStatus(arr[0].serial_no);
  //     // console.log("Array...", arr);
  //     // console.log("Filter...", arr[0].serial_no);
  //     // console.log("OrderStatus...", reverseArr);
  //     // for(let i=1; i<= reverseArr.length; i++ ){
  //     //   serialArr.push(i)
  //     // }
  //   }
  //   getProductDetailByUserId();
  // };

  const getProductDetailByUserId = async () => {
    let dataSend = {
      invoice_id: invoice,
    };
    let result = await requestData(
      "user/fetchaddtocartbyinvoice",
      "POST",
      dataSend
    );
    console.log("ProdData", result);
    if (result && result.data) {
      setProductDetail(result.data);
      setBillingAddress(result.data[0].billing_address);
      setShippingAddress(result.data[0].shipping_address);
      let sum = 0;
      for (let item of result.data) {
        sum += parseInt(item.total_price);
      }
      setTotalPrice(sum);
      console.log("ProductDetail...", result.data);
      // console.log("TotalPrice...", sum);
    }
  };

  // const placedOrderStatus = async () => {
  //   if (orderStatus !== "") {
  //     const dataSend = {
  //       invoice_id: invoice,
  //       order_status: orderStatus,
  //     };
  //     let data = await requestData(
  //       "admin/UserOrderStatusChange",
  //       "POST",
  //       dataSend
  //     );
  //     if (data && data.status) {
  //       console.log("PlaceOrder", data.data);
  //       setChangeStatus(data.serial_no);
  //       // console.log("SerialNo...", data.serial_no);
  //       // let serialArr = []
  //       // for(let i=0; i< data.serial_no; i++){
  //       //   serialArr.push(i)
  //       // }
  //       // console.log("SerialArr...", serialArr);
  //       // setStatusCount(serialArr)
  //       setOrderStatus("");
  //       toast("Order status changed Successfully!", {
  //         position: "bottom-right",
  //         autoClose: 1000,
  //         hideProgressBar: false,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: true,
  //         progress: undefined,
  //       });
  //     } else {
  //       toast("Server Busy! Try again after sometime", {
  //         position: "bottom-right",
  //         autoClose: 1000,
  //         hideProgressBar: false,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: true,
  //         progress: undefined,
  //       });
  //     }
  //   } else {
  //     toast("All Fields Required", {
  //       position: "bottom-right",
  //       autoClose: 1000,
  //       hideProgressBar: false,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: undefined,
  //     });
  //   }
  // };

  // const downloadInvoice = async (invoice) => {
  //   // let dataSend = {
  //   //   invoice: invoice
  //   // }
  //   let result = await requestData(
  //     `https://skychat.easytodb.com/Bookswali/invoice_bill.php?invoice=${invoice}`,
  //     "POST",
  //   );
  //   if(result && result.status){
  //     toast("Invoice downloaded succesfully!", {
  //       position: "bottom-right",
  //       autoClose: 3000,
  //       hideProgressBar: false,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: undefined,
  //     });
  //   }
  //   else{
  //     toast("Something went wrong!", {
  //       position: "bottom-right",
  //       autoClose: 3000,
  //       hideProgressBar: false,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: undefined,
  //     });
  //   }
  // }

  return (
    <Layout sidebar>
      {console.log("Order", allOrderStatus)}
      <main className="enrollment">
        {/* {console.log("Change..", changeStatus, "Order...", orderStatus)} */}
        <div className="container-fluid">
          <div className="row">
            <div
              className="p-4 shadow"
              style={{
                height: "100%",
                width: "100%",
                background: "#fff",
                borderRadius: 5,
              }}
            >
              <div className="col-md-8 col-lg-8 col-xl-8 col-12"></div>
              <div className="col-md-4 col-lg-4 col-xl-4 col-12 ml-auto p-0">
                <div className="text-right">
                  <a
                    className="down-btn"
                    target="_blank"
                    href={`http://43.205.232.125/bookswaliBack/invoice_bill.php?invoice=${invoice}`}
                  >
                    <i className="fa-solid fa-cloud-arrow-up" />
                    Download-invoice
                  </a>
                </div>
              </div>
              <div className="row mt-4 mb-4 d-flex d-sm-flex align-items-center">
                <div className="col-md-4 col-12">
                  <h3 style={{ fontSize: "18px" }}>
                    Order ID - <span className="dynamic-id">{invoice}</span>
                  </h3>
                </div>
                {/* <div className="col-md-4 col-12">
                  <div className="form-group">
                    <select
                      className="form-control"
                      id="exampleFormControlSelect1"
                      value={orderStatus}
                      onChange={(e) => {
                        setOrderStatus(e.target.value);
                      }}
                    >
                      <option value="">Select Order Status</option>
                      {allOrderStatus.length > 0 &&
                        allOrderStatus.map((item, index) => {
                          return (
                            <option value={item.status} key={index}>
                              {item.status}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div> */}
                {/* <div className="col-md-4 col-12">
                  <button
                    onClick={placedOrderStatus}
                    href="#"
                    className="btn btn-status"
                  >
                    Change Status
                  </button>
                </div> */}
              </div>
              {trackingStatus !== "" ?
                <div>
                  <p>{trackingStatus}</p>
                </div> :
                <div className="">
                  <h5>Current Status: {currentStatus}</h5>
                  <div className="progress-container">
                    {allOrderStatus &&
                      allOrderStatus.map((item, index) => {
                        // setChangeStatus(changeStatus + 1)
                        // console.log("changeStatus..",changeStatus+'--'+index)
                        return (
                          <div
                            className={`progress_Info active`}
                            key={index}
                          >
                            <div className="d-flex align-items-center justify-content-center custom-progress">
                              <div className="circle">
                                {index + 1 ? (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20.894"
                                    height={16}
                                    viewBox="0 0 20.894 16"
                                    className="w-full"
                                  >
                                    <path
                                      data-name="_ionicons_svg_ios-checkmark (3)"
                                      d="M169.184,175.473l-1.708-1.756a.367.367,0,0,0-.272-.116.352.352,0,0,0-.272.116l-11.837,11.925-4.308-4.308a.375.375,0,0,0-.543,0l-1.727,1.727a.387.387,0,0,0,0,.553l5.434,5.434a1.718,1.718,0,0,0,1.135.553,1.8,1.8,0,0,0,1.126-.534h.01l12.973-13.041A.415.415,0,0,0,169.184,175.473Z"
                                      transform="translate(-148.4 -173.6)"
                                      fill="currentColor"
                                    />
                                  </svg>
                                ) : (
                                  <div className="circle ">{index + 1}</div>
                                )}
                              </div>
                            </div>
                            <span className="confirmed_Text">{item.activity}</span>
                          </div>
                        )

                      })}
                  </div>
                </div>
              }

              <div
                className="col-md-12 col-12 col-xl-12 col-lg-12"
                style={{ marginTop: 40 }}
              >
                <div
                  className="card recentTable_Card"
                  style={{ border: "transparent" }}
                >
                  {productDetail.length > 0 ?
                    <div className="card-body p-0">
                      <div className="table-responsive">
                        <table className="table table-hover">
                          <thead>
                            <tr>
                              <th style={{ textAlign: "left" }}>Image</th>
                              <th style={{ textAlign: "left" }}>Products</th>
                              <th style={{ textAlign: "right" }}>Total</th>
                            </tr>
                          </thead>

                          {productDetail.length > 0 ? (
                            productDetail.map((item, index) => (
                              <tbody key={index}>
                                <tr>
                                  <td style={{ textAlign: "left" }}>
                                    <img
                                      src={`${IMG_URL}${item.product_image}`}
                                      alt=""
                                    />
                                  </td>
                                  <td style={{ textAlign: "left" }}>
                                    {item.product_name} x{" "}
                                    <span className="total-item">
                                      {item.product_count}
                                    </span>
                                  </td>
                                  <td style={{ textAlign: "right" }}>
                                    <span className="price">
                                      ₹ {item.total_price}
                                    </span>
                                  </td>
                                </tr>
                              </tbody>
                            ))
                          ) : null
                          }
                        </table>
                      </div>
                      <div className="order-history">
                        <div className="flex">
                          <div className="d-flex align-items-center justify-content-between">
                            <span>Sub total</span>
                            <span>₹ {totalPrice}</span>
                          </div>
                          {/* <div className="d-flex align-items-center justify-content-between">
                        <span>Tax</span>
                        <span>€0.40</span>
                      </div> */}
                          <div className="d-flex align-items-center justify-content-between">
                            <span>Delivery fee</span>
                            <span>₹ 0.00</span>
                          </div>
                          {/* <div className="d-flex align-items-center justify-content-between">
                        <span>Discount</span>
                        <span />
                      </div> */}
                          <div className="d-flex align-items-center justify-content-between">
                            <span>Total</span>
                            <span>₹ {totalPrice}</span>
                          </div>
                        </div>
                      </div>
                      <div className="row ">
                        <div className="col-md-6 py-2">
                          <span className="font-weight-bold">
                            Billing Address
                          </span>
                          <hr className="my-2" />
                          {billingAddress ? (
                            <span>{billingAddress}</span>
                          ) : (
                            <span>No Address</span>
                          )}
                        </div>
                        <div className="col-md-6 py-2">
                          <span className="font-weight-bold">
                            Shipping Address
                          </span>
                          <hr className="my-2" />
                          {shippingAddress ? (
                            <span>{shippingAddress}</span>
                          ) : (
                            <span>No Address</span>
                          )}
                        </div>
                      </div>
                    </div> :
                    <h5>Product Deleted! So the order is canceled</h5>
                  }

                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </Layout>
  );
};

export default OrderDetail;
