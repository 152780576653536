import React, { useState, useEffect, useRef } from "react";

// Helpers
import { toast } from "react-toastify";

// API calls
import { fileUplode, requestData } from "../../Utils/HttpClient";
import { BANNER_IMG_URL } from "../../Utils/HttpClient";

// Components
import Layout from "../../Layout/DashboardLayout/Index";
import Pagination from "../../Component/Pagination/Pagination";

import "./style.css";

export default function PopupBanner() {
  const fileInputRef = useRef(null);
  const [popupBanner, setPopupBanner] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [postPerPage, setPostPerPage] = useState(10);
  const [popBannerEditId, setPopBannerEditId] = useState("");
  const [popupBannerImg, setPopupBannerImg] = useState(null);

  useEffect(() => {
    getPopupBanners();
  }, []);

  const lastPostIndex = currentPage * postPerPage;
  const firstPostIndex = lastPostIndex - postPerPage;
  const currentPagePosts = popupBanner.slice(firstPostIndex, lastPostIndex);

  const getPopupBanners = async () => {
    let result = await requestData("admin/fetch_popbanner", "GET");
    // console.log("Pop", result);
    if (result && result.status) {
      setPopupBanner(result.data);
      // console.log("Banner...", result.data);
    }
    // getAllStaticBanners();
    // getAllSideBanners();
  };

  const addPopBanner = async (event) => {
    event.preventDefault();

    if (popupBannerImg === null) {
      toast.error("No image selected.");
    } else {
      if (
        popupBannerImg.type == "image/png" ||
        popupBannerImg.type == "image/jpeg"
      ) {
        const DATA = new FormData();
        DATA.append("popbanner_image", popupBannerImg);
        let res = await fileUplode("admin/addpopbanner", "POST", DATA);
        console.log("upload file", res);
        if (res && res.status) {
          setPopBannerEditId("");
          getPopupBanners();
          if (fileInputRef.current) {
            fileInputRef.current.value = "";
          }
          toast.success("Popup Banner Added Successfully!");
          setPopupBannerImg(null);
        } else {
          toast.error("Something went wrong!");
        }
      } else {
        toast.error("File is not a .png or .jpg type");
      }
    }
  };

  const editPopupBanner = async () => {
    let data = new FormData();
    data.append("image", popupBannerImg);
    let res = await fileUplode("admin/popupbanner", "POST", data);
    console.log("Upload", res);
    if (res && res.status) {
      setPopBannerEditId("");
      getPopupBanners();
      toast.success("Popup Banner Updated Successfully!");
    } else {
      toast.error("Something went wrong!");
    }
    console.log("Upload", res);
  };

  const handleDelete = async (id) => {
    let data = { banner_id: id };
    let res = await requestData("admin/delete_popbanner", "POST", data);

    if (res && res.status) {
      getPopupBanners();
      toast.success("Banner successfully deleted.");
    } else {
      toast.error("Something went wrong.")
    }
  };

  return (
    <Layout sidebar>
      <main className="enrollment">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 col-12 col-xl-12 col-lg-12">
              {popBannerEditId !== "" ? (
                <div className="bannerBox">
                  <div className="bannerLeft">
                    <h1 className="text-heading">Banners</h1>
                  </div>
                  <div className="form-group upload_img">
                    {/* <label htmlFor="">Uplod</label> */}
                    <input
                      onChange={(e) => setPopupBannerImg(e.target.files[0])}
                      type="file"
                      id=""
                      className="form-control"
                      placeholder="Upload file"
                    />
                  </div>
                  <div className="formBtn">
                    <div
                      className="form-group has-search"
                      style={{ width: "10%" }}
                    >
                      <span className="fa fa-search form-control-feedback invisible" />
                      <input
                        type="text"
                        className="form-control invisible"
                        placeholder="Type your query and press enter"
                      />
                    </div>
                    <button
                      onClick={editPopupBanner}
                      className="btn btn-addCategory"
                    >
                      + Edit
                    </button>
                  </div>
                </div>
              ) : (
                <div className="bannerBox">
                  <div className="bannerLeft">
                    <h1 className="text-heading">Add Banner</h1>
                  </div>
                  <div className="form-group upload_img">
                    {/* <label htmlFor="">Uplod</label> */}
                    <input
                      onChange={(e) => setPopupBannerImg(e.target.files[0])}
                      type="file"
                      id=""
                      ref={fileInputRef}
                      className="form-control"
                      placeholder="Upload file"
                    />
                  </div>
                  <div className="bannerRight formBtn">
                    <button
                      onClick={(e) => addPopBanner(e)}
                      className="btn btn-addCategory"
                    >
                      + Add
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="row">
            <div
              className="col-md-12 col-12 col-xl-12 col-lg-12"
              style={{ marginTop: 40 }}
            >
              <div className="card recentTable_Card">
                <div className="card-body p-0">
                  <div className="table-responsive">
                    <h4
                      style={{
                        fontSize: ".875rem",
                        marginBottom: "0",
                        padding: "10px",
                      }}
                    >
                      Admin Banner
                    </h4>
                    <table className="table table-hover banner">
                      <thead>
                        <tr>
                          <th>SL NO</th>
                          <th className="text-center">Image</th>
                          <th>Manage Banner</th>
                        </tr>
                      </thead>
                      {Array.isArray(currentPagePosts) ? (
                        currentPagePosts.map((item, index) => (
                          <tbody key={index}>
                            <tr>
                              <td className="text-center">{index + 1}</td>
                              <td className="text-center">
                                <img
                                  src={`${BANNER_IMG_URL}${item.popbanner_image}`}
                                  alt=""
                                  className="img-fluid"
                                />
                              </td>
                              <td>
                                {/* <div className="gap-8 inline-flex w-auto items-center">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20.547 20.299"
                                    fill="currentColor"
                                    width={16}
                                    onClick={() => setPopBannerEditId(item?.id)}
                                  >
                                    <g stroke="currentColor" strokeWidth=".4">
                                      <path
                                        data-name="Path 78"
                                        d="M18.659 12.688a.5.5 0 00-.5.5v4.423a1.5 1.5 0 01-1.494 1.494H2.691A1.5 1.5 0 011.2 17.609V4.629a1.5 1.5 0 011.494-1.494h4.419a.5.5 0 100-1H2.691A2.493 2.493 0 00.2 4.629v12.98A2.493 2.493 0 002.691 20.1h13.976a2.493 2.493 0 002.491-2.491v-4.423a.5.5 0 00-.5-.5zm0 0"
                                      />
                                      <path
                                        data-name="Path 79"
                                        d="M18.96.856a2.241 2.241 0 00-3.17 0L6.899 9.739a.5.5 0 00-.128.219l-1.169 4.219a.5.5 0 00.613.613l4.219-1.169a.5.5 0 00.219-.128l8.886-8.887a2.244 2.244 0 000-3.17zm-10.971 9.21l7.273-7.273 2.346 2.346-7.273 7.273zm-.469.94l1.879 1.875-2.592.718zm11.32-7.1l-.528.528-2.346-2.345.528-.528a1.245 1.245 0 011.761 0l.585.584a1.247 1.247 0 010 1.761zm0 0"
                                      />
                                    </g>
                                  </svg>
                                  </Link>
                                </div> */}
                                <button
                                  className="text-red-500 transition duration-200 hover:text-red-600 focus:outline-none"
                                  title="Delete"
                                  onClick={() => handleDelete(item.id)}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 19.4 22.169"
                                    fill="currentColor"
                                    width={16}
                                  >
                                    <g
                                      fill="none"
                                      stroke="currentColor"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="1.4"
                                    >
                                      <path
                                        data-name="Rectangle 2"
                                        d="M8.238.7h2.923a2 2 0 012 2v.769h0-6.923 0V2.7a2 2 0 012-2z"
                                      />
                                      <path
                                        data-name="Line 1"
                                        d="M.7 3.469h18"
                                      />
                                      <path
                                        data-name="Path 77"
                                        d="M14.649 21.469h-9.9a1.385 1.385 0 01-1.38-1.279L2.085 3.469h15.231L16.029 20.19a1.385 1.385 0 01-1.38 1.279z"
                                      />
                                      <path
                                        data-name="Line 2"
                                        d="M7.623 6.238V18.7"
                                      />
                                      <path
                                        data-name="Line 3"
                                        d="M11.777 6.238V18.7"
                                      />
                                    </g>
                                  </svg>
                                </button>
                              </td>
                            </tr>
                          </tbody>
                        ))
                      ) : null}
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <PaginatedItems itemsPerPage={4} /> */}
        <Pagination
          totalPosts={popupBanner?.length}
          postPerPage={postPerPage}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
        />
      </main>
    </Layout>
  );
}
