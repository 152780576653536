import React, { useState } from 'react'
import r1 from "../../images/image03-thumbnail.jpg";
import r2 from "../../images/turbo_support_6.jpg";
import modalimg from "../../images/Isomorphic.jpg";
import PaginatedItems from "../../Component/Pagination/Index";
import Layout from '../../Layout/DashboardLayout/Index';


export default function Index() {
    const [modal, setModal] = useState(false);
    return (
        <Layout sidebar>
            <main className="enrollment">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 col-12 col-xl-12 col-lg-12">
                            <div className="reviewBox">
                                <div className="reviewLeft">
                                    <h1 className="text-heading">Reviews</h1>
                                </div>
                            </div>
                        </div>
                        <div
                            className="col-md-12 col-12 col-xl-12 col-lg-12"
                            style={{ marginTop: 40 }}
                        >
                            <div className="card recentTable_Card">
                                <div className="card-body p-0">
                                    <div className="table-responsive">
                                        <table className="table table-hover table-review">
                                            <thead>
                                                <tr>
                                                    <th style={{ textAlign: "left" }}>Image</th>
                                                    <th style={{ textAlign: "left" }}>Customer Review</th>
                                                    <th style={{ textAlign: "center" }}>Ratings</th>
                                                    <th style={{ textAlign: "left" }}>Products</th>
                                                    <th style={{ textAlign: "center" }}>Reports</th>
                                                    <th style={{ textAlign: "left" }}>Date</th>
                                                    <th style={{ textAlign: "right" }}>Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td style={{ textAlign: "left" }}>
                                                        <img
                                                            src={r1}
                                                            alt=""

                                                            style={{ height: "58px", width: "58px" }}
                                                        />
                                                    </td>
                                                    <td style={{ textAlign: "left" }}>
                                                        <div className="block">
                                                            <div
                                                                className="mb-3 flex items-center text-xs text-gray-500"
                                                                style={{ display: "flex", alignItems: "center" }}
                                                            >
                                                                By{" "}
                                                                <span className="customcapitalize text-heading">
                                                                    Customer
                                                                </span>
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    viewBox="0 0 13 13"
                                                                    className="sv-hght"
                                                                >
                                                                    <g data-name="Group 36431" fill="currentColor">
                                                                        <path
                                                                            data-name="Path 22671"
                                                                            d="M6.5,0A6.5,6.5,0,1,0,13,6.5,6.508,6.508,0,0,0,6.5,0Zm3.633,4.789L5.979,8.911a.639.639,0,0,1-.9.016l-2.2-2a.661.661,0,0,1-.049-.912.644.644,0,0,1,.912-.033l1.743,1.6L9.2,3.861a.657.657,0,0,1,.929.929Z"
                                                                        />
                                                                    </g>
                                                                </svg>
                                                            </div>
                                                            <p className="leading-6">Foo</p>
                                                            <div className="space-s">
                                                                <div className="mb-1">
                                                                    <span
                                                                        style={{
                                                                            boxSizing: "border-box",
                                                                            display: "inline-block",
                                                                            overflow: "hidden",
                                                                            width: "initial",
                                                                            height: "initial",
                                                                            background: "none",
                                                                            opacity: 1,
                                                                            border: 0,
                                                                            margin: 0,
                                                                            padding: 0,
                                                                            position: "relative",
                                                                            maxWidth: "100%"
                                                                        }}
                                                                    >
                                                                        <span
                                                                            style={{
                                                                                boxSizing: "border-box",
                                                                                display: "block",
                                                                                width: "initial",
                                                                                height: "initial",
                                                                                background: "none",
                                                                                opacity: 1,
                                                                                border: 0,
                                                                                margin: 0,
                                                                                padding: 0,
                                                                                maxWidth: "100%"
                                                                            }}
                                                                        >
                                                                            <img
                                                                                alt="image"
                                                                                src={r2}
                                                                                style={{
                                                                                    width:
                                                                                        "30px, height: 30px, border-radius: 5px, object-fit: cover"
                                                                                }}
                                                                            />
                                                                        </span>
                                                                    </span>
                                                                </div>
                                                                <a href="#" className="view-images btn" onClick={() => { setModal(!modal) }}>
                                                                    View Images
                                                                </a>
                                                            </div>
                                                            <div className="mt-3 d-flex items-center space-x-4">
                                                                <span className="flex items-center text-xs tracking-wider text-gray-400 transition">
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 17.067 16"
                                                                        className="me-1.5 h-4 w-4"
                                                                    >
                                                                        <g
                                                                            id="like_1_"
                                                                            data-name="like (1)"
                                                                            transform="translate(0 -0.75)"
                                                                            fill="currentColor"
                                                                        >
                                                                            <path
                                                                                id="Path_22672"
                                                                                data-name="Path 22672"
                                                                                d="M1.244,17.063H3.022a1.164,1.164,0,0,0,1.244-1.057V9.057A1.164,1.164,0,0,0,3.022,8H1.244A1.164,1.164,0,0,0,0,9.057v6.948A1.164,1.164,0,0,0,1.244,17.063Z"
                                                                                transform="translate(0 -1.25)"
                                                                            />
                                                                            <path
                                                                                id="Path_22673"
                                                                                data-name="Path 22673"
                                                                                d="M11.255.75c-.711,0-1.067.356-1.067,2.133,0,1.69-1.636,3.049-2.689,3.75v8.809a16.575,16.575,0,0,0,6.955,1.308h1.138a2.84,2.84,0,0,0,2.8-2.361l.8-4.622a2.843,2.843,0,0,0-2.8-3.328H13.033a6.867,6.867,0,0,0,.533-2.844A2.611,2.611,0,0,0,11.255.75Z"
                                                                                transform="translate(-2.167 0)"
                                                                            />
                                                                        </g>
                                                                    </svg>
                                                                    0
                                                                </span>
                                                                <span className="flex items-center text-xs tracking-wider text-gray-400 transition">
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 17.067 16"
                                                                        className="me-1.5 h-4 w-4"
                                                                        style={{ marginLeft: 20 }}
                                                                    >
                                                                        <path
                                                                            id="Path_22674"
                                                                            data-name="Path 22674"
                                                                            d="M19.244,1h1.778a1.166,1.166,0,0,1,1.244,1.065v7a1.166,1.166,0,0,1-1.244,1.065H19.244A1.166,1.166,0,0,1,18,9.06v-7A1.166,1.166,0,0,1,19.244,1Z"
                                                                            transform="translate(-18 0.188)"
                                                                            fill="currentColor"
                                                                        />
                                                                        <path
                                                                            id="Path_22675"
                                                                            data-name="Path 22675"
                                                                            d="M8.093.75a2.84,2.84,0,0,1,2.8,2.361l.8,4.622a2.843,2.843,0,0,1-2.8,3.328H5.533a6.867,6.867,0,0,1,.533,2.844A2.611,2.611,0,0,1,3.755,16.75c-.711,0-1.067-.356-1.067-2.133,0-1.69-1.636-3.049-2.689-3.75V2.058A16.575,16.575,0,0,1,6.955.75Z"
                                                                            transform="translate(5.333 -0.75)"
                                                                            fill="currentColor"
                                                                        />
                                                                    </svg>
                                                                    0
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td style={{ textAlign: "center" }}>
                                                        <div className="review-flex items-center px-3 py-0.5">
                                                            5
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                viewBox="0 0 25.056 24"
                                                                className="h-3 w-3 ms-1"
                                                            >
                                                                <g data-name="Group 36413" fill="currentColor">
                                                                    <path
                                                                        id="Path_22667"
                                                                        data-name="Path 22667"
                                                                        d="M19.474,34.679l-6.946-4.346L5.583,34.679a.734.734,0,0,1-1.1-.8L6.469,25.93.263,20.668a.735.735,0,0,1,.421-1.3l8.1-.566,3.064-7.6a.765.765,0,0,1,1.362,0l3.064,7.6,8.1.566a.735.735,0,0,1,.421,1.3L18.588,25.93l1.987,7.949a.734.734,0,0,1-1.1.8Z"
                                                                        transform="translate(0 -10.792)"
                                                                    />
                                                                </g>
                                                            </svg>
                                                        </div>
                                                    </td>
                                                    <td style={{ textAlign: "left" }}>
                                                        <a href="#" className="products">
                                                            Shoppie UI Kit PSD E-Commerce-Designvorlage
                                                        </a>
                                                    </td>
                                                    <td style={{ textAlign: "center" }}>0</td>
                                                    <td style={{ textAlign: "left" }}>2 months ago</td>
                                                    <td style={{ textAlign: "right" }}>
                                                        <div className="gap-8 inline-flex w-auto items-center">
                                                            <button
                                                                className="text-red-500 transition duration-200 hover:text-red-600 focus:outline-none"
                                                                title="Delete"
                                                            >
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    viewBox="0 0 19.4 22.169"
                                                                    fill="currentColor"
                                                                    width={16}
                                                                >
                                                                    <g
                                                                        fill="none"
                                                                        stroke="currentColor"
                                                                        strokeLinecap="round"
                                                                        strokeLinejoin="round"
                                                                        strokeWidth="1.4"
                                                                    >
                                                                        <path
                                                                            data-name="Rectangle 2"
                                                                            d="M8.238.7h2.923a2 2 0 012 2v.769h0-6.923 0V2.7a2 2 0 012-2z"
                                                                        />
                                                                        <path data-name="Line 1" d="M.7 3.469h18" />
                                                                        <path
                                                                            data-name="Path 77"
                                                                            d="M14.649 21.469h-9.9a1.385 1.385 0 01-1.38-1.279L2.085 3.469h15.231L16.029 20.19a1.385 1.385 0 01-1.38 1.279z"
                                                                        />
                                                                        <path data-name="Line 2" d="M7.623 6.238V18.7" />
                                                                        <path data-name="Line 3" d="M11.777 6.238V18.7" />
                                                                    </g>
                                                                </svg>
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style={{ textAlign: "left" }}>
                                                        <img
                                                            src={r1}
                                                            alt=""

                                                            style={{ height: "58px", width: "58px" }}
                                                        />
                                                    </td>
                                                    <td style={{ textAlign: "left" }}>
                                                        <div className="block">
                                                            <div
                                                                className="mb-3 flex items-center text-xs text-gray-500"
                                                                style={{ display: "flex", alignItems: "center" }}
                                                            >
                                                                By{" "}
                                                                <span className="customcapitalize text-heading">
                                                                    Customer
                                                                </span>
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    viewBox="0 0 13 13"
                                                                    className="sv-hght"
                                                                >
                                                                    <g data-name="Group 36431" fill="currentColor">
                                                                        <path
                                                                            data-name="Path 22671"
                                                                            d="M6.5,0A6.5,6.5,0,1,0,13,6.5,6.508,6.508,0,0,0,6.5,0Zm3.633,4.789L5.979,8.911a.639.639,0,0,1-.9.016l-2.2-2a.661.661,0,0,1-.049-.912.644.644,0,0,1,.912-.033l1.743,1.6L9.2,3.861a.657.657,0,0,1,.929.929Z"
                                                                        />
                                                                    </g>
                                                                </svg>
                                                            </div>
                                                            <p
                                                                className="leading-6"
                                                                style={{ maxWidth: 200, overflow: "hidden" }}
                                                            >
                                                                Item is good but the support is horrible had to wait 2
                                                                weeks to fix a bug
                                                            </p>
                                                            <div className="mt-3 d-flex items-center space-x-4">
                                                                <span className="flex items-center text-xs tracking-wider text-gray-400 transition">
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 17.067 16"
                                                                        className="me-1.5 h-4 w-4"
                                                                    >
                                                                        <g
                                                                            id="like_1_"
                                                                            data-name="like (1)"
                                                                            transform="translate(0 -0.75)"
                                                                            fill="currentColor"
                                                                        >
                                                                            <path
                                                                                id="Path_22672"
                                                                                data-name="Path 22672"
                                                                                d="M1.244,17.063H3.022a1.164,1.164,0,0,0,1.244-1.057V9.057A1.164,1.164,0,0,0,3.022,8H1.244A1.164,1.164,0,0,0,0,9.057v6.948A1.164,1.164,0,0,0,1.244,17.063Z"
                                                                                transform="translate(0 -1.25)"
                                                                            />
                                                                            <path
                                                                                id="Path_22673"
                                                                                data-name="Path 22673"
                                                                                d="M11.255.75c-.711,0-1.067.356-1.067,2.133,0,1.69-1.636,3.049-2.689,3.75v8.809a16.575,16.575,0,0,0,6.955,1.308h1.138a2.84,2.84,0,0,0,2.8-2.361l.8-4.622a2.843,2.843,0,0,0-2.8-3.328H13.033a6.867,6.867,0,0,0,.533-2.844A2.611,2.611,0,0,0,11.255.75Z"
                                                                                transform="translate(-2.167 0)"
                                                                            />
                                                                        </g>
                                                                    </svg>
                                                                    0
                                                                </span>
                                                                <span className="flex items-center text-xs tracking-wider text-gray-400 transition">
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 17.067 16"
                                                                        className="me-1.5 h-4 w-4"
                                                                        style={{ marginLeft: 20 }}
                                                                    >
                                                                        <path
                                                                            id="Path_22674"
                                                                            data-name="Path 22674"
                                                                            d="M19.244,1h1.778a1.166,1.166,0,0,1,1.244,1.065v7a1.166,1.166,0,0,1-1.244,1.065H19.244A1.166,1.166,0,0,1,18,9.06v-7A1.166,1.166,0,0,1,19.244,1Z"
                                                                            transform="translate(-18 0.188)"
                                                                            fill="currentColor"
                                                                        />
                                                                        <path
                                                                            id="Path_22675"
                                                                            data-name="Path 22675"
                                                                            d="M8.093.75a2.84,2.84,0,0,1,2.8,2.361l.8,4.622a2.843,2.843,0,0,1-2.8,3.328H5.533a6.867,6.867,0,0,1,.533,2.844A2.611,2.611,0,0,1,3.755,16.75c-.711,0-1.067-.356-1.067-2.133,0-1.69-1.636-3.049-2.689-3.75V2.058A16.575,16.575,0,0,1,6.955.75Z"
                                                                            transform="translate(5.333 -0.75)"
                                                                            fill="currentColor"
                                                                        />
                                                                    </svg>
                                                                    0
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td style={{ textAlign: "center" }}>
                                                        <div className="review-flex items-center px-3 py-0.5">
                                                            5
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                viewBox="0 0 25.056 24"
                                                                className="h-3 w-3 ms-1"
                                                            >
                                                                <g data-name="Group 36413" fill="currentColor">
                                                                    <path
                                                                        id="Path_22667"
                                                                        data-name="Path 22667"
                                                                        d="M19.474,34.679l-6.946-4.346L5.583,34.679a.734.734,0,0,1-1.1-.8L6.469,25.93.263,20.668a.735.735,0,0,1,.421-1.3l8.1-.566,3.064-7.6a.765.765,0,0,1,1.362,0l3.064,7.6,8.1.566a.735.735,0,0,1,.421,1.3L18.588,25.93l1.987,7.949a.734.734,0,0,1-1.1.8Z"
                                                                        transform="translate(0 -10.792)"
                                                                    />
                                                                </g>
                                                            </svg>
                                                        </div>
                                                    </td>
                                                    <td style={{ textAlign: "left" }}>
                                                        <a href="#" className="products">
                                                            Shoppie UI Kit PSD E-Commerce-Designvorlage
                                                        </a>
                                                    </td>
                                                    <td style={{ textAlign: "center" }}>0</td>
                                                    <td style={{ textAlign: "left" }}>2 months ago</td>
                                                    <td style={{ textAlign: "right" }}>
                                                        <div className="gap-8 inline-flex w-auto items-center">
                                                            <button
                                                                className="text-red-500 transition duration-200 hover:text-red-600 focus:outline-none"
                                                                title="Delete"
                                                            >
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    viewBox="0 0 19.4 22.169"
                                                                    fill="currentColor"
                                                                    width={16}
                                                                >
                                                                    <g
                                                                        fill="none"
                                                                        stroke="currentColor"
                                                                        strokeLinecap="round"
                                                                        strokeLinejoin="round"
                                                                        strokeWidth="1.4"
                                                                    >
                                                                        <path
                                                                            data-name="Rectangle 2"
                                                                            d="M8.238.7h2.923a2 2 0 012 2v.769h0-6.923 0V2.7a2 2 0 012-2z"
                                                                        />
                                                                        <path data-name="Line 1" d="M.7 3.469h18" />
                                                                        <path
                                                                            data-name="Path 77"
                                                                            d="M14.649 21.469h-9.9a1.385 1.385 0 01-1.38-1.279L2.085 3.469h15.231L16.029 20.19a1.385 1.385 0 01-1.38 1.279z"
                                                                        />
                                                                        <path data-name="Line 2" d="M7.623 6.238V18.7" />
                                                                        <path data-name="Line 3" d="M11.777 6.238V18.7" />
                                                                    </g>
                                                                </svg>
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <PaginatedItems itemsPerPage={4}/> */}
            </main>
            {modal && <div className='ReviewModal'>
                <div className='Wrapper'>

                    <div className='WrapperModal'>
                        <a onClick={() => { setModal(!modal) }}><i className="fa-sharp fa-solid fa-circle-xmark"></i></a>
                        <div className="modalimg">
                            <img src={modalimg} />
                        </div>
                    </div>
                </div>
            </div>}
        </Layout>
    )
}
