import React, { useState, useEffect } from "react";
import { requestData, fileUplode } from "../../Utils/HttpClient";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { SIDE_IMG_URL } from "../../Utils/HttpClient";
import Layout from "../../Layout/DashboardLayout/Index";

export default function SidebannerEdit() {
  const [image, setImage] = useState("");
  const [title, setTitle] = useState("");
  const [desc, setDesc] = useState("");
  const [uploadImage, setUploadImage] = useState("");

  const nagigate = useNavigate();

  useEffect(() => {
    FilterBanner();
  }, []);

  const FilterBanner = async () => {
    let result = await requestData("admin/sidebanner", "POST");
    if (result && result.status) {
      setTitle(result.data.title);
      setDesc(result.data.description);
      setUploadImage(`${SIDE_IMG_URL}${result.data.image}`);
    }
  };

  const handleBanner = (e) => {
    setImage(e.target.files[0]);
    setUploadImage(URL.createObjectURL(e.target.files[0]));
  };

  const editSideBanner = async () => {
    //   alert();
    if (title !== "" && desc !== "") {
      let data = new FormData();
      data.append("image", image);
      data.append("title", title);
      data.append("description", desc);
      // console.log(data);

      let result = await fileUplode("admin/sidebanner", "POST", data);
      // console.log(result)
      if (result && result.status) {
        toast("Side Banners Added Successfully!", {
          position: "bottom-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setTimeout(function () {
          nagigate("/banner");
        }, 3000);
      } else {
        toast("Server Busy! Try again after sometime", {
          position: "bottom-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } else {
      toast("All Fields are Required", {
        position: "bottom-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  return (
    <Layout sidebar>
      <main className="enrollment">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-4 col-12">
              <div className="description">
                <h4 className="text-base mb-2">Description</h4>
                <p className="text-body ">
                  Edit your side banner details and necessary information from
                  here
                </p>
              </div>
            </div>
            <div className="col-xl-8 col-lg-8 col-md-8 col-12 createCategoryCustom">
              <div className="form-group">
                <label>Edit StaticBanners</label>
                {/* <p className="staticBannerCnt text-danger">
                  You must add SideBanner Image here before Editing
                </p> */}
              </div>
              <div className="form-group">
                <label>Title</label>
                <input
                  type="text"
                  placeholder="Enter Title"
                  className="form-control"
                  style={{ width: "100%" }}
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label>Description</label>
                <input
                  type="text"
                  placeholder="Enter Description"
                  className="form-control"
                  style={{ width: "100%" }}
                  value={desc}
                  onChange={(e) => setDesc(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label>Image</label>
                <input
                  type="file"
                  id="myfile"
                  name="myfile"
                  className="form-control"
                  style={{ width: "100%" }}
                  onChange={handleBanner}
                />
              </div>
              {uploadImage && (
                <div className="col-md-12 my-2">
                  <img src={uploadImage} height="100px" widtth="400px" />
                </div>
              )}
            </div>
          </div>

          <div className="row mt-5">
            <div className="col-xl-9 col-lg-9 col-12 col-md-9"></div>
            <div className="col-xl-3 col-lg-3 col-12 col-md-3">
              <button
                onClick={editSideBanner}
                href="#"
                className="btn btn-addCategory "
              >
                Edit Side Banner
              </button>
            </div>
          </div>
        </div>
      </main>
    </Layout>
  );
}
