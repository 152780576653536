import React, { useState } from "react";
import { useRef } from "react";
import { FcUpload } from "react-icons/fc";
import { toast } from "react-toastify";
import { fileUplode, requestData } from "../../Utils/HttpClient";

var excelRegex = /\.(csv)$/i;

function BulkUploadModal({ close, getAllProduct }) {
  const [uploadText, setUploadtext] = useState(
    "Drag and drop or click to upload csv"
  );
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const drop = useRef();

  const filehandle = (file) => {
    if (!file) return;
    if (excelRegex.test(file.name)) {
      setFile(file);

      // setUploadText("Are you sure you want to upload");
    } else {
      toast.error("Only .csv file accept");
      //   setError(true);
      //   setAlertMessage("Only Excel file");
    }
  };

  const uploadHandle = async () => {
    setLoading(true);
    let data = new FormData();
    data.append("productFile", file);

    let result = await fileUplode("admin/uploadBulkProducts", "POST", data);
    console.log("ref", result);
    if (result && result.status) {
      close();
      getAllProduct();
    } else {
      toast.error(result.error);
    }
    setLoading(false);
  };


  React.useEffect(() => {
    drop.current.addEventListener("dragover", handleDragOver);
    drop.current.addEventListener("drop", handleDrop);

    return () => {
      if (drop.current) {
        drop.current.removeEventListener("dragover", handleDragOver);
        drop.current.removeEventListener("drop", handleDrop);
      }
    };
  }, []);

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const { files } = e.dataTransfer;
    // console.log("files",files);
    filehandle(files[0]);

  };
  return (
    <div id="openModal-about" className="modalDialog">
      <div>
        <div ref={drop}>
          <label htmlFor="uploadFile" className="dragBox">
            <div style={{ cursor: "pointer" }}>
              <FcUpload
                style={{
                  color: "rgb(70, 70, 153)",
                  height: "70px",
                  width: "100%",
                }}
              />
            </div>
            <div
            // className="hideSroll"
            // style={{ height: "8em" }}
            >
          {

            loading?"uploading Please wait ...":
            ( file ? file.name : uploadText)
          }   
            </div>
          </label>{" "}
          <input
            //   disabled={uploadSuccess}
            type="file"
            id="uploadFile"
            onChange={(val) => filehandle(val.target.files[0])}
          />
        </div>

        <div className="modalFooter mt-2">
          <button
            className="submitBtn btn-danger"
            //   style={{ opacity: file === null && 0.5 }}
            //   disabled={file === null}
            onClick={() => close()}
          >
            Close
          </button>

          {/* <div style={{ marginRight: "5px", color: "green" }}>
            {file?.name}
          </div> */}
          {file && (
            <div
              style={{ display: "flex", position: "absolute", left: "15px" }}
            >
              <button className="btn btn-success" onClick={uploadHandle}>
                Upload
              </button>

              {/* <Loading /> */}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default BulkUploadModal;
