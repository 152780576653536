import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { signin } from "../Action/authAction";
import logo from "../images/download.png";
import { toast } from "react-toastify";
import { requestData } from "../Utils/HttpClient";
// import useNavigate

export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordShown, setPasswordShown] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  let location = useLocation();
  let from = location.pathname
  console.log(location);
  console.log(auth);

  useEffect(() => {
    if (auth.authenticate) {
      navigate("/")
    }
    else {
      navigate("/login");
    }
  }, [auth.authenticate])


  const adminSignin = async () => {
    let user = {
      email,
      password,
    };
    console.log(user);
    if (email !== "" || password !== "") {
      dispatch(signin(user));
      const result = await requestData("admin/adminlogin", "POST", user);
      if (result && result.status) {
        if (from === '/login') {
          navigate("/");
        }
      }
    }
    else {
      toast("All Fields are Required", {
        position: "bottom-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const togglePassword = () => {
    // When the handler is invoked
    // inverse the boolean state of passwordShown
    setPasswordShown(!passwordShown);
  };

  return (
    <>
      <section className="login">
        <div className="login_Overflow" />
        <div className="login_Inner d-flex align-items-center justify-content-center">
          <div className="login_Start w-100">
            <div className="d-flex align-items-center justify-content-center">
              <div className="logo_iMage">
                <img src={logo} alt="" className="img-fluid" />
              </div>
            </div>
            <form action="" className="">
              <h3 className="mb-6 mt-4 text-center login_TExt">
                Login to admin
              </h3>
              <div className="form-group mb-4">
                <label htmlFor="name" className="mb-2">
                  Email
                </label>
                <input
                  type="text"
                  className="form-control"
                  required
                  placeholder="Enter Email"
                  onChange={(e) => setEmail(e.target.value)}
                />
                <div className="valid-feedback">Valid.</div>
                <div className="invalid-feedback">
                  Please fill out this field.
                </div>
              </div>
              <div className="form-group mb-4">
                <div className="d-flex align-items-center justify-content-between mb-2">
                  <label htmlFor="email" className="mb-0">
                    Password
                  </label>
                  {/* <a
                    className="forgot_Password text-decoration-none"
                    href="/forgot-password"
                  >
                    Forgot password?
                  </a> */}
                </div>
                <div className="position-relative">
                  <input
                    type={passwordShown ? "text" : "password"}
                    className="form-control"
                    required
                    placeholder="Enter Password"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  {passwordShown ? (
                    <i
                      onClick={togglePassword}
                      className="fa-regular fa-eye-slash password_Icons"
                    ></i>
                  ) : (
                    <i
                      onClick={togglePassword}
                      className="fa-solid fa-eye password_Icons"
                    />
                  )}
                  <div className="valid-feedback">Valid.</div>
                  <div className="invalid-feedback">
                    Please fill out this field.
                  </div>
                </div>
              </div>

              <button
                type="button"
                onClick={adminSignin}
                className="login_BTn align-items-center justify-content-center px-5 py-0 h-12 w-100"
              >
                Login
              </button>
            </form>
          </div>
        </div>
      </section>
    </>
  );
}
