import React, { useMemo, useState } from 'react'

function SearchableDropdown({ data }) {
    const [query, setQuery] = useState("")

    const [listShow, setListShow] = useState(false)

    const filterData = useMemo(() => {
        return data.filter(item => item.includes(query))
    }, [query])
    return (
        <div className='customSearch serachCustom' onClick={() => setListShow(!listShow)}>
            <input type="text" placeholder='Pending' value={query} onChange={(val) => {
                // setListShow(true)
                setQuery(val.target.value)
            }} className="form-control" />
            {listShow && <div className='customDropdown'>
                {filterData.length > 0 ? filterData.map((item) => { return <li onClick={()=>setQuery(item)}>{item}</li> }) : <li>no result found</li>}
            </div>}
        </div>
    )
}

export default SearchableDropdown