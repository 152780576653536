import { authAction } from "../Reducer/authReducer";
import { requestData } from "../Utils/HttpClient";
import { reactLocalStorage } from "reactjs-localstorage";
import { toast } from "react-toastify";


export const signin = (user) => {
  
  return async (dispatch) => {
    dispatch(authAction.loginRequest());
    const result = await requestData("admin/adminlogin", "POST", user);
    console.log(result);
    if (result && result.status) {
      toast("Login Successfully!", {
        position: "bottom-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      dispatch(authAction.loginSuccess(result.data.email));
      reactLocalStorage.setObject("Admin", {
        AdminUser: result.data,
      });
    }
    else{
      toast(result.message, {
        position: "bottom-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      dispatch(authAction.logOut())
    }
  };
};
