import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { requestData } from "../../Utils/HttpClient";
import { toast } from "react-toastify";
import { fileUplode } from "../../Utils/HttpClient";
import Layout from "../../Layout/DashboardLayout/Index";
import { useLocation } from "react-router-dom";

export default function StaticBannerEdit() {
  const [multiUploadImage, setMultiUploadImage] = useState("");
  const [multiImage, setMultiImage] = useState("");

  const [params] = useSearchParams();
  const navigate = useNavigate();
// const {state}=useLocation()
  let bannerName = params.get("banner");
  let banner = params.get("bannerName");
  // console.log("BrandId...", state);
  // console.log("BannerName...", banner);
  // setMultiImageFetch(bannerName)

  useEffect(() => {
    // FilterBanner();
  }, []);

  // const FilterBanner = async () => {
  //   let result = await requestData("admin/staticbanner", "POST");
  //   if (result && result.status) {
  //     // let obj = {
  //     //   banner: [
  //     //     result.data.banner1,
  //     //     result.data.banner2,
  //     //     result.data.banner3,
  //     //     result.data.banner4,
  //     //   ],
  //     //   id: result.data.id,
  //     // };
  //     setMultiImageFetch(result.data.bannerName);
  //     // console.log("Object", obj)
  //     // let arr = obj.length > 0 && obj.filter((it) => it.id === parseInt(id));
  //     // if (arr.length > 0) {
  //     //   // console.log(arr[0].banner)
  //     // }
  //   }
  // };

  const handleBanner = (e) => {
    // bannerName = ""
    // console.log(bannerName);
    // setMultiImageFetch([]);
    setMultiImage(e.target.files[0]);
    // console.log("Banner", [...multiBanner, e.target.files[0]]);
    setMultiUploadImage(
      URL.createObjectURL(e.target.files[0]),
    );
  };

  const editBanner = async () => {
    let data = new FormData();
    // console.log("BannerName...", state.name);
    data.append(banner, multiImage);

    let result = await fileUplode("admin/staticbanner", "POST", data);
    // console.log(result)
    if (result && result.status) {
      toast("Static Banners Added Successfully!", {
        position: "bottom-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setTimeout(function () {
        navigate("/banner");
      }, 3000);
    } else {
      toast("Server Busy! Try again after sometime", {
        position: "bottom-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  return (
    <Layout sidebar>
      <main className="enrollment">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-4 co+-lg-4 col-md-4 col-12">
              <div className="description">
                <h4 className="text-base mb-2">Description</h4>
                <p className="text-body ">
                  Edit your static banners details and necessary information from
                  here
                </p>
              </div>
            </div>
            <div className="col-xl-8 col-lg-8 col-md-8 col-12 createCategoryCustom">
              <div className="form-group">
                <label>Edit StaticBanners</label>
                {/* <p className="staticBannerCnt text-danger">
                  You must add Atleast 1 StaticBanner Image here before Editing
                </p> */}
              </div>
              <div className="form-group">
                <label>Image</label>
                <input
                  type="file"
                  id="myfile"
                  name="myfile"
                  className="form-control"
                  style={{ width: "100%" }}
                  onChange={handleBanner}
                />
              </div>
              {multiUploadImage !== ''
                &&
                // <div className="col-md-12 my-2">
                //   <img
                //     src={`http://skychat.easytodb.com/Bookswali/public/staticbanner_image/${bannerName}`}
                //     height="30px"
                //     widtth="50px"
                //   />
                // </div>
                 multiUploadImage &&
                // multiUploadImage.map((item, index) => (
                  <div className="col-md-12 my-2">
                    <img src={multiUploadImage} height="30px" widtth="50px" />
                  </div>
                // ))
                }
            </div>
          </div>

          <div className="row mt-5">
            <div className="col-xl-9 col-lg-9 col-12 col-md-9"></div>
            <div className="col-xl-3 col-lg-3 col-12 col-md-3">
              <button
                onClick={editBanner}
                href="#"
                className="btn btn-addCategory "
              >
                Edit Static Banner
              </button>
            </div>
          </div>
        </div>
      </main>
    </Layout>
  );
}
