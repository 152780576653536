import React, {useEffect} from 'react'
import { useSelector } from 'react-redux';
import Sidebar from '../../Layout/DashboardLayout/Sidebar'
import Dashboard from './Dashboard'
import Layout from "../../Layout/DashboardLayout/Index";
// import { useLocation, useNavigate } from 'react-router-dom';

export default function Index() {
  const auth = useSelector((state) => state.auth);
  // const navigate = useNavigate();
  // let location = useLocation();
  // let from = location.pathname

  // useEffect(() => {
  //   if (auth.authenticate) {
  //     navigate("/")
  //   }
  //   // else if (from === '/login') {
  //   //   navigate('/')
  //   // }
  //   else {
  //     navigate("/login");
  //   }
  // }, [auth.authenticate])

  console.log(auth);
  return (
    <Layout sidebar>
      <Dashboard />
    </Layout>
  )
}
