import React, { useState, useEffect } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import SearchableDropdown from "../../Component/SearchableDropdown/SearchableDropdown";
import { fileUplode, requestData } from "../../Utils/HttpClient";
import { toast } from "react-toastify";
import Layout from "../../Layout/DashboardLayout/Index";

export default function EditOrderStatus() {

  const [orderStatus, setOrderStatus] = useState("")
  const [serialNo, setSerialNo] = useState("")
  const [colorCode, setColorCode] = useState("#000000")
  const [params] = useSearchParams();
  let id = params.get('orderStatusId')

  // console.log("OrderStatusId...", id);

  const navigate = useNavigate();

  useEffect(() => {
    filterOrderStatus()
  }, []);

  const filterOrderStatus = async () => {
    let result = await requestData("admin/fetchorderstatus", "POST");
    if (result && result.status) {
      let arr = result.data.length > 0 && result.data.filter((it) => it.id === parseInt(id));
      if (arr.length > 0) {
        // console.log(arr);
        setOrderStatus(arr[0].status);
        setSerialNo(arr[0].serial_no);
        setColorCode(arr[0].color);
      }
    }
  };

  const editOrderStatus = async (e) => {
    e.preventDefault();
    if (orderStatus !== '' && serialNo !== '' && colorCode !== '') {
      let dataSend = {
        id: id,
        status: orderStatus,
        serial_no: serialNo,
        color: colorCode
      };
      // console.log("SendData...", dataSend);
      // console.log("dataSend", dataSend);
      let result = await requestData("admin/updateorderstatus", "POST", dataSend);
      if (result && result.status) {
        // console.log(result)
        toast("Order Status edited Successfully!", {
          position: "bottom-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setTimeout(function () {
          navigate("/order-status");
        }, 2000);
      } else {
        toast("Server Busy! Try again after sometime", {
          position: "bottom-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } else {
      toast("All Fields are Required", {
        position: "bottom-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  return (
    <Layout sidebar>
      <main className="enrollment">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-4 col-12">
              <div className="description">
                <h4 className="text-base mb-2">Description</h4>
                <p className="text-body ">
                  Edit your order status and necessary information from here
                </p>
              </div>
            </div>

            <div className="col-xl-8 col-lg-8 col-md-8 col-12 createCategoryCustom">
              <div className="form-group">
                <label>Name</label>
                <div className="row">
                  <div className="col-md-12">
                    <input
                      className="form-control"
                      type="text"
                      value={orderStatus}
                      onChange={(e) => setOrderStatus(e.target.value)}
                    />
                  </div>
                </div>
              </div>

              <div className="form-group">
                <label>Serial</label>
                <div className="row">
                  <div className="col-md-12">
                    <input
                      className="form-control"
                      type="number"
                      value={serialNo}
                      onChange={(e) => setSerialNo(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label>Colour</label>
                <div className="row">
                  <div className="col-md-12">
                    <div className="ColorPalet">
                      <div><input
                        id="color"
                        name="color"
                        type="color"
                        className="color-picker_color_picker__BNom4"
                        autoComplete="off"
                        autoCorrect="off"
                        autoCapitalize="off"
                        spellCheck="false"
                        aria-invalid="false"
                        value={colorCode}
                        onChange={(e) => setColorCode(e.target.value)}
                      /></div>
                      <div>
                        <span className="ms-3 px-2 py-1 text-sm text-heading bg-gray-100 border border-border-200 rounded">
                          {colorCode ? colorCode : '#000000'}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
            </div>
          </div>

          <div className="row mt-5">
            <div className="col-lg-8 col-md-8 col-12" style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
            <div className="form-group custProduct">
                <Link to="/order-status">
                <button type="submit" className="btn btn1">
                  Go Back
                </button>
                </Link>
                
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-12">
            <div className="form-group custProduct">
                <button onClick={editOrderStatus} type="button" className="btn btn2">
                  Edit Order Status
                </button>
              </div>
            </div>
          </div>
        </div>
      </main>
    </Layout>
  );
}
