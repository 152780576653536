import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { requestData } from "../../Utils/HttpClient";
import { BANNER_IMG_URL } from "../../Utils/HttpClient";
import { toast } from "react-toastify";
import { fileUplode } from "../../Utils/HttpClient";
import Layout from "../../Layout/DashboardLayout/Index";

export default function ManageBanner() {
  const [banner, setBanner] = useState("");
  const [bannerLine, setBannerLine] = useState("");
  const [uploadImage, setUploadImage] = useState("");

  const [params] = useSearchParams();
  const navigate = useNavigate()

  let id = params.get("bannerId");
  // console.log("BannerId...", id);

  useEffect(() => {
    FilterBanner();
  }, []);

  const FilterBanner = async () => {
    let result = await requestData("admin/fetch_banner", "POST");
    if (result && result.status) {
      let arr =
        result.data.length > 0 &&
        result.data.filter((it) => it.id === parseInt(id));
      if (arr.length > 0) {
        // console.log(arr[0]);
        setBannerLine(arr[0].banner_line ? arr[0].banner_line : "");
        setUploadImage(`${BANNER_IMG_URL}${arr[0].banner_image}`);
        // setBanner(new File([arr[0].banner_image], arr[0].banner_image, { type: "text/plain"}))
      }
    }
  };

  const handleBanner = (e) => {
    setBanner(e.target.files[0]);
    setUploadImage(URL.createObjectURL(e.target.files[0]));
  };

  const editBanner = async () => {
    //   alert();
    if (bannerLine !== "") {
      let data = new FormData()
      data.append("bannerid", id)
      data.append("bannerimg", banner)
      data.append("bannerlink", bannerLine)
      // console.log(data)

      let result = await fileUplode("admin/edit_banner", "POST", data)
      // console.log(result)
      if (result && result.status) {
        toast("Banner Edited Successfully!", {
          position: "bottom-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
        setTimeout(function () {
          navigate("/banner")
        }, 3000)
      } else {
        toast("Server Busy! Try again after sometime", {
          position: "bottom-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
      }
    } else {
      toast("All Fields are Required", {
        position: "bottom-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
  }

  return (
    <Layout sidebar>
      <main className="enrollment">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-4 col-12">
              <div className="description">
                <h4 className="text-base mb-2">Description</h4>
                <p className="text-body ">
                  Edit your admin banner details and necessary information from
                  here
                </p>
              </div>
            </div>
            <div className="col-xl-8 col-lg-8 col-md-8 col-12 createCategoryCustom">
              <div className="form-group">
                <label>Banner Link</label>
                <input
                  type="text"
                  placeholder="Enter Banner Link"
                  className="form-control"
                  style={{ width: "100%" }}
                  value={bannerLine}
                  onChange={(e) => setBannerLine(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label>Image</label>
                <input
                  type="file"
                  id="myfile"
                  name="myfile"
                  className="form-control"
                  style={{ width: "100%" }}
                  onChange={handleBanner}
                />
              </div>
              <div className="col-md-12 my-2">
                <img src={uploadImage} height="100px" widtth="100px" />
              </div>
            </div>
          </div>

          <div className="row mt-5">
            <div className="col-xl-9 col-lg-9 col-12 col-md-9"></div>
            <div className="col-xl-3 col-lg-3 col-12 col-md-3">
              <button onClick={editBanner} href="#" className="btn btn-addCategory ">
                Edit Banner
              </button>
            </div>
          </div>
        </div>
      </main>
    </Layout>
  );
}
