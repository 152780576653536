import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { ToastContainer, toast } from "react-toastify";
import { requestData } from "../../Utils/HttpClient";
import "react-toastify/dist/ReactToastify.css";
import { useSearchParams } from "react-router-dom";
import Layout from "../../Layout/DashboardLayout/Index";
import { ColorRing } from "react-loader-spinner";
import { Link } from "react-router-dom";

export default function EditVendor() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");

  const [loader, setLoader] = useState(true);

  const navigate = useNavigate();
  const [params] = useSearchParams();
  let id = params.get("vendorId");
  //   console.log("VendorParamsId",id);

  useEffect(() => {
    filterVendor();
  });

  const filterVendor = async () => {
    let result = await requestData("admin/fetch_vendor", "POST");
    if (result && result.status) {
      let arr =
        result.data.length > 0 &&
        result.data.filter((it) => it.id === parseInt(id));
      if (arr.length > 0) {
        // console.log(arr);
        setLoader(false)
        setName(arr[0].name);
        setEmail(arr[0].email);
        setPhone(arr[0].phone);
        setAddress(arr[0].address);
      }
    }
  };

  const editVendor = async () => {
    //   alert();
    if (name !== "" && email !== "" && phone !== "" && address !== "") {
      let dataSend = {
        vendor_id: id,
        name: name,
        email: email,
        password: password,
        phone: phone,
        address: address,
      };
      // console.log(dataSend)
      // return false
      let result = await requestData("admin/edit_vendor", "POST", dataSend);
      // console.log(result)
      if (result && result.status) {
        toast("Vendor edited Successfully!", {
          position: "bottom-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setTimeout(function () {
          navigate("/vendor");
        }, 2000);
      } else {
        toast("Vendor already exists! Try a new one...", {
          position: "bottom-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } else {
      toast("All Fields are Required", {
        position: "bottom-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  return (
    <Layout sidebar>
      <main className="enrollment">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-4 col-12">
              <div className="description">
                <h4 className="text-base mb-2">Description</h4>
                <p className="text-body ">
                  Edit your vendor details and necessary information from here
                </p>
              </div>
            </div>
            <div className="col-xl-8 col-lg-8 col-md-8 col-12 createCategoryCustom">
              <div className="form-group">
                <label>Name</label>
                <input
                  type="text"
                  placeholder="Enter Name"
                  className="form-control"
                  style={{ width: "100%" }}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label>Email</label>
                <input
                  type="email"
                  placeholder="Enter Email"
                  className="form-control"
                  style={{ width: "100%" }}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label>Password</label>
                <input
                  type="password"
                  placeholder="Enter Password"
                  className="form-control"
                  style={{ width: "100%" }}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <p className="text-danger">Password should be confidential</p>
              </div>
              <div className="form-group">
                <label>Phone No</label>
                <input
                  type="number"
                  placeholder="Enter Phone No"
                  className="form-control"
                  style={{ width: "100%" }}
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label>Address</label>
                <textarea
                  id="w3review"
                  name="w3review"
                  rows={4}
                  cols={50}
                  style={{ width: "100%", resize: "none" }}
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                />
              </div>
            </div>
          </div>

          <div className="row mt-5">
            <div className="col-xl-8 col-lg-8 col-12 col-md-8" style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
            <Link to={"/vendor"}>
              <button type="button" href="#" className="btn btn-addCategory ">
                Back
              </button>
            </Link>
            </div>
            <div className="col-xl-4 col-lg-4 col-12 col-md-4">
              <button onClick={editVendor} className="btn btn-addCategory ">
                Edit Vendor
              </button>
            </div>
            
          </div>
        </div>
      </main>
      {loader ? (
        <span
          style={{
            display: "flex",
            position: "fixed",
            zIndex: "1000",
            left: "0",
            top: "0",
            right: "0",
            margin: "0 auto",
            background: "rgba(0,0,0,0.3)",
            justifyContent: "center",
            height: "100vh",
            alignItems: "center",
          }}
        >
          <ColorRing
            visible={true}
            id="colorRing"
            height="80"
            width="80"
            ariaLabel="blocks-loading"
            wrapperStyle={{}}
            wrapperclassName="blocks-wrapper"
            colors={["#f21322", "#0cb3f0", "#f58716", "#f00cd9", "#d50cf0"]}
          />
        </span>
      ) : null}
    </Layout>
  );
}
