import React, { useState, useEffect } from 'react'
import { BRAND_IMG_URL, fileUplode, requestData } from '../../Utils/HttpClient'
import { toast } from "react-toastify";
import { Link, useNavigate } from 'react-router-dom';
import { useSearchParams } from "react-router-dom";
import Layout from '../../Layout/DashboardLayout/Index';

export default function EditBrand() {

  const [brand, setBrand] = useState("")
  const [image, setImage] = useState("")
  const [uploadImage, setUploadImage] = useState("")

  const nagigate = useNavigate()
  const [params] = useSearchParams();

  let id = params.get("brandId");
  // console.log("BrandId...", id);

  useEffect(() => {
    FilterBrand()
  }, [])

  const handleImage = e => {
    setImage(e.target.files[0])
    setUploadImage(URL.createObjectURL(e.target.files[0]))
  }

  const FilterBrand = async () => {
    let result = await requestData("admin/fetch_brand", "POST")
    if (result && result.status) {
      let arr = result.data.length > 0 && result.data.filter(it => it.id === parseInt(id))
      if (arr.length > 0) {
        setBrand(arr[0].brand_name)
        setUploadImage(`${BRAND_IMG_URL}${arr[0].brand_image}`)
      }
    }
  }

  const editBrand = async () => {
    //   alert();
    if (brand !== "") {
      let form = new FormData()
      form.append('brand_id', id)
      form.append('brand_name', brand)
      form.append('brand_image', image)

      let result = await fileUplode(
        "admin/edit_brand",
        "POST",
        form
      )
      //   console.log(result)
      if (result && result.status) {
        toast("Brand edited Successfully!", {
          position: "bottom-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
        setTimeout(function () {
          nagigate("/brands")
        }, 2000)
      } else {
        toast("Brand already exists! Try a new one...", {
          position: "bottom-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
      }
    } else {
      toast("All Fields are Required", {
        position: "bottom-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
  }
  return (
    <Layout sidebar>
      <main className="enrollment">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-4 col-12">
              <div className="description">
                <h4 className="text-base mb-2">Description</h4>
                <p className="text-body ">
                  Edit your brand details and necessary information from here
                </p>
              </div>
            </div>
            <div className="col-xl-8 col-lg-8 col-md-8 col-12 createCategoryCustom">
              <div className='form-group'>
                <label>Brand Name</label>
                <input
                  type="text"
                  placeholder='Enter Brand Name'
                  className='form-control'
                  style={{ width: "100%" }}
                  value={brand}
                  onChange={(e) => setBrand(e.target.value)}
                />
              </div>
              <div className='form-group'>
                <label>Image</label>
                <input
                  type="file"
                  id="myfile"
                  name="myfile"
                  className='form-control'
                  style={{ width: "100%" }}
                  onChange={handleImage}
                />
              </div>
              {uploadImage && <div className="col-md-12 my-2">
                <img src={uploadImage} height="100px" widtth="100px" />
              </div>
              }
            </div>
          </div>

          <div className="row mt-5">
            <div className="col-xl-8 col-lg-8 col-12 col-md-8" style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
            <Link to={"/brands"}>
              <button type="button" href="#" className="btn btn-addCategory ">
                Back
              </button>
            </Link>
            </div>
            <div className="col-xl-4 col-lg-4 col-12 col-md-4">
              <button onClick={editBrand} href="#" className="btn btn-addCategory ">
                Add Brand
              </button>
            </div>
            
          </div>
        </div>
      </main>
    </Layout>
  )
}
