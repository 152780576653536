import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { requestData } from "../../Utils/HttpClient";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PaginatedItems from "../../Component/Pagination/Index";
import { ColorRing } from "react-loader-spinner";
import Pagination from "../../Component/Pagination/Pagination";
import Layout from "../../Layout/DashboardLayout/Index";


export default function Index() {
  const [getAge, setGetAge] = useState([]);
  const [age, setAge] = useState("");
  const [editId, setEditId] = useState("");
  const [postPerPage, setPostPerPage] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)

  useEffect(() => {
    getAllAge();
  }, []);

  useEffect(() => {
    FilterAge();
  }, [editId]);

  const getAllAge = async () => {
    let result = await requestData("admin/fetchallage", "POST");
    if (result && result.status) {
      setGetAge(result.data);
      // console.log("AllAge", result.data);
    }
  };

  const lastPostIndex = currentPage * postPerPage
  const firstPostIndex = lastPostIndex - postPerPage
  const currentPost = getAge.slice(firstPostIndex, lastPostIndex)

  const FilterAge = async () => {
    let result = await requestData("admin/fetchallage", "POST");
    if (result && result.status) {
      let arr =
        result.data.length > 0 && result.data.filter((it) => it.id === editId);
      if (arr.length > 0) {
        // console.log(arr);
        setAge(arr[0].age_duration);
      }
    }
  };

  const handelDelete = async (id) => {
    if (window.confirm("Do you want to Delete Age?") === true) {
      if (id !== "") {
        // console.log(id)
        let dataSend = {
          ageid: id.toString(),
        };
        let res = await requestData("admin/deleteage", "POST", dataSend);
        if (res && res.status) {
          toast("Age Deleted Successfully!", {
            position: "bottom-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          getAllAge();
        } else {
          toast("Server Busy! Try again after sometime", {
            position: "bottom-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      } else {
        toast("All Fields are Required", {
          position: "bottom-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  };

  const handleUpdateAge = async () => {
    // console.log("AgeId", id)
    if (age !== "") {
      let dataSend = {
        ageid: editId,
        age: age,
      };
      // console.log(dataSend)
      // return false
      let result = await requestData("admin/editage", "POST", dataSend);
      // console.log(result)
      if (result && result.status) {
        toast("Age Updated Successfully!", {
          position: "bottom-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        getAllAge();
        setEditId("");
        setAge("");
        // setTimeout(function () {
        //   history.push("/age")
        // }, 2000)
      } else {
        toast("Server Busy! Try again after sometime", {
          position: "bottom-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } else {
      toast("All Fields are Required", {
        position: "bottom-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  
  const handleAgeChange = (event) => {
    event.preventDefault();
    setAge(event.target.value.replace(/[a-z]/gi, ''));
  }

  const submitAge = async () => {
    if (age !== "") {
      let data = {
        age: age,
      };
      // console.log(data)
      // return false
      let result = await requestData("admin/addage", "POST", data);
      // console.log(result)
      if (result && result.status) {
        toast("Age Added Successfully!", {
          position: "bottom-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        getAllAge();
      } else {
        toast("Age already exists! Try a new one...", {
          position: "bottom-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } else {
      toast("All Fields are Required", {
        position: "bottom-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  return (
    <Layout sidebar>
      <main className="enrollment">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 col-12 col-xl-12 col-lg-12">
              <div className="AgeBox custAgeWrap" style={{ display: "flex" }}>
                <div className="AgeLeft">
                  <h1 className="text-heading">Add Age Range</h1>
                </div>
                <div className="AgeformBtn">
                  <div className="form-group has-search">
                    <input
                      type="text"
                      name="age"
                      className="form-control"
                      placeholder="E.g. 10-15"
                      value={age}
                      onChange={(e) => handleAgeChange(e)} // setAge(e.target.value)
                    />
                  </div>
                  {editId !== "" ? (
                    <button
                      onClick={handleUpdateAge}
                      className="btn btn-addCategory"
                    >
                      Edit
                    </button>
                  ) : (
                    <button onClick={submitAge} className="btn btn-addCategory">
                      Add
                    </button>
                  )}
                </div>
              </div>
            </div>
            <div
              className="col-md-12 col-12 col-xl-12 col-lg-12"
              style={{ marginTop: 40 }}
            >
              <div className="card recentTable_Card">
                <div className="card-body p-0">
                  <div className="table-responsive">
                    <table className="table table-hover">
                      <thead>
                        <tr>
                          <th className="text-center">Sl No</th>
                          <th className="text-center"> Age Duration</th>
                          <th className="text-center">Manage Age</th>
                        </tr>
                      </thead>
                      {currentPost.length > 0 ? (
                        currentPost.map((item, index) => (
                          <tbody key={index}>
                            <tr>
                              <td>{index + 1}</td>
                              <td
                                className="text-center"
                                style={{ color: "rgba(13,153,101,1)" }}
                              >
                                {item.age_duration}
                              </td>

                              <td className="text-center">
                                <div className="gap-8 inline-flex w-auto items-center">
                                  <button
                                    className="text-red-500 transition duration-200 hover:text-red-600 focus:outline-none"
                                    title="Delete"
                                    onClick={() => handelDelete(item.id)}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 19.4 22.169"
                                      fill="currentColor"
                                      width={16}
                                    >
                                      <g
                                        fill="none"
                                        stroke="currentColor"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="1.4"
                                      >
                                        <path
                                          data-name="Rectangle 2"
                                          d="M8.238.7h2.923a2 2 0 012 2v.769h0-6.923 0V2.7a2 2 0 012-2z"
                                        />
                                        <path
                                          data-name="Line 1"
                                          d="M.7 3.469h18"
                                        />
                                        <path
                                          data-name="Path 77"
                                          d="M14.649 21.469h-9.9a1.385 1.385 0 01-1.38-1.279L2.085 3.469h15.231L16.029 20.19a1.385 1.385 0 01-1.38 1.279z"
                                        />
                                        <path
                                          data-name="Line 2"
                                          d="M7.623 6.238V18.7"
                                        />
                                        <path
                                          data-name="Line 3"
                                          d="M11.777 6.238V18.7"
                                        />
                                      </g>
                                    </svg>
                                  </button>
                                  <button
                                    className="text-base transition duration-200 hover:text-heading"
                                    href="/categories/free/edit"
                                    onClick={() => {
                                      setEditId(item.id);
                                      FilterAge();
                                    }}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 20.547 20.299"
                                      fill="currentColor"
                                      width={16}
                                    >
                                      <g stroke="currentColor" strokeWidth=".4">
                                        <path
                                          data-name="Path 78"
                                          d="M18.659 12.688a.5.5 0 00-.5.5v4.423a1.5 1.5 0 01-1.494 1.494H2.691A1.5 1.5 0 011.2 17.609V4.629a1.5 1.5 0 011.494-1.494h4.419a.5.5 0 100-1H2.691A2.493 2.493 0 00.2 4.629v12.98A2.493 2.493 0 002.691 20.1h13.976a2.493 2.493 0 002.491-2.491v-4.423a.5.5 0 00-.5-.5zm0 0"
                                        />
                                        <path
                                          data-name="Path 79"
                                          d="M18.96.856a2.241 2.241 0 00-3.17 0L6.899 9.739a.5.5 0 00-.128.219l-1.169 4.219a.5.5 0 00.613.613l4.219-1.169a.5.5 0 00.219-.128l8.886-8.887a2.244 2.244 0 000-3.17zm-10.971 9.21l7.273-7.273 2.346 2.346-7.273 7.273zm-.469.94l1.879 1.875-2.592.718zm11.32-7.1l-.528.528-2.346-2.345.528-.528a1.245 1.245 0 011.761 0l.585.584a1.247 1.247 0 010 1.761zm0 0"
                                        />
                                      </g>
                                    </svg>
                                  </button>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        ))
                      ) : null
                      // (
                      //   <span
                      //     style={{
                      //       display: "flex",
                      //       position: "fixed",
                      //       zIndex: "1000",
                      //       left: "0",
                      //       top: "0",
                      //       right: "0",
                      //       margin: "0 auto",
                      //       background: "rgba(0,0,0,0.3)",
                      //       justifyContent: "center",
                      //       height: "100vh",
                      //       alignItems: "center",
                      //     }}
                      //   >
                      //     <ColorRing
                      //       visible={true}
                      //       id="colorRing"
                      //       height="80"
                      //       width="80"
                      //       ariaLabel="blocks-loading"
                      //       wrapperStyle={{}}
                      //       wrapperclassName="blocks-wrapper"
                      //       colors={[
                      //         '#f21322', '#0cb3f0', '#f58716', '#f00cd9', '#d50cf0'
                      //       ]}
                      //     />
                      //   </span>
                      // )
                      }
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <PaginatedItems itemsPerPage={4} /> */}
        <Pagination
          totalPosts={getAge.length}
          postPerPage={postPerPage}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
        />
      </main>
    </Layout>
  );
}
