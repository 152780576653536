import React, { useState, useEffect } from 'react'
import PaginatedItems from "../../Component/Pagination/Index";
import { Link } from 'react-router-dom';
import { requestData } from '../../Utils/HttpClient';
import { ColorRing } from "react-loader-spinner";
import Layout from '../../Layout/DashboardLayout/Index';

export default function Index() {
    const [allOrderStatus, setAllOrderStatus] = useState("")

    useEffect(() => {
        getAllOrderStatus()
    }, [])

    const getAllOrderStatus = async () => {
        let result = await requestData("admin/fetchorderstatus", 'POST');
        if (result && result.data) {
            let reverseArr = []
            for (let item of result.data) {
                reverseArr.push(item)
            }
            setAllOrderStatus(reverseArr)
            // console.log("OrderStatus...", reverseArr);
        }
    }

    return (
        <Layout sidebar>
            <main className="enrollment">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 col-12 col-xl-12 col-lg-12">
                            <div className=" OrderStatusBox custOrderStatusWrap" style={{ display: "flex", alignItems: "center" }} >
                                <div className="categoryLeft">
                                    <h1 className="text-heading">Order Status</h1>
                                </div>
                                <div className=" OrderstatusBtn">
                                    <div className="form-group has-search">
                                        <span className="fa fa-search form-control-feedback invisible" />
                                        <input
                                            type="text"
                                            className="form-control invisible"
                                            placeholder="Type your query and press enter"
                                        />
                                    </div>
                                    <Link to={'/create-order-status'} className="btn btn-addCategory" style={{ marginLeft: "20px" }}>
                                        + Add
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div
                            className="col-md-12 col-12 col-xl-12 col-lg-12"
                            style={{ marginTop: 40 }}
                        >
                            <div className="card recentTable_Card">
                                <div className="card-body p-0">
                                    <div className="table-responsive">
                                        <table className="table table-hover">
                                            <thead>
                                                <tr>
                                                    <th className='text-center'>ID</th>
                                                    <th className='text-left'> Name</th>
                                                    <th className='text-center'>Serial</th>
                                                    <th className='text-right'>Actions</th>
                                                </tr>
                                            </thead>
                                            {allOrderStatus.length > 0 ? allOrderStatus.map((item, index) => (
                                                <tbody key={index}>
                                                    <tr>
                                                        <td>{item.id}</td>
                                                        <td className='text-left' style={{ color: `${item.color}` }}>{item.status}</td>
                                                        <td>{item.serial_no}</td>
                                                        <td className='text-right'>
                                                            <div className="gap-8 inline-flex w-auto items-center">
                                                                <Link
                                                                    to={`/edit-order-status/orderStatus?orderStatusId=${item.id}`}
                                                                    className="text-base transition duration-200 hover:text-heading"
                                                                    href="/categories/free/edit"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 20.547 20.299"
                                                                        fill="currentColor"
                                                                        width={16}
                                                                    >
                                                                        <g stroke="currentColor" strokeWidth=".4">
                                                                            <path
                                                                                data-name="Path 78"
                                                                                d="M18.659 12.688a.5.5 0 00-.5.5v4.423a1.5 1.5 0 01-1.494 1.494H2.691A1.5 1.5 0 011.2 17.609V4.629a1.5 1.5 0 011.494-1.494h4.419a.5.5 0 100-1H2.691A2.493 2.493 0 00.2 4.629v12.98A2.493 2.493 0 002.691 20.1h13.976a2.493 2.493 0 002.491-2.491v-4.423a.5.5 0 00-.5-.5zm0 0"
                                                                            />
                                                                            <path
                                                                                data-name="Path 79"
                                                                                d="M18.96.856a2.241 2.241 0 00-3.17 0L6.899 9.739a.5.5 0 00-.128.219l-1.169 4.219a.5.5 0 00.613.613l4.219-1.169a.5.5 0 00.219-.128l8.886-8.887a2.244 2.244 0 000-3.17zm-10.971 9.21l7.273-7.273 2.346 2.346-7.273 7.273zm-.469.94l1.879 1.875-2.592.718zm11.32-7.1l-.528.528-2.346-2.345.528-.528a1.245 1.245 0 011.761 0l.585.584a1.247 1.247 0 010 1.761zm0 0"
                                                                            />
                                                                        </g>
                                                                    </svg>
                                                                </Link>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            ))
                                                :
                                                <span
                                                    style={{
                                                        display: "flex",
                                                        position: "fixed",
                                                        zIndex: "1000",
                                                        left: "0",
                                                        top: "0",
                                                        right: "0",
                                                        margin: "0 auto",
                                                        background: "rgba(0,0,0,0.3)",
                                                        justifyContent: "center",
                                                        height: "100vh",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <ColorRing
                                                        visible={true}
                                                        id="colorRing"
                                                        height="80"
                                                        width="80"
                                                        ariaLabel="blocks-loading"
                                                        wrapperStyle={{}}
                                                        wrapperclassName="blocks-wrapper"
                                                        colors={[
                                                            '#f21322', '#0cb3f0', '#f58716', '#f00cd9', '#d50cf0'
                                                        ]}
                                                    />
                                                </span>
                                            }

                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <PaginatedItems itemsPerPage={4}/> */}
            </main>
        </Layout>
    )
}
