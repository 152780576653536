import React, { useState } from "react";
import SearchableDropdown from "../../Component/SearchableDropdown/SearchableDropdown";
import { toast } from "react-toastify";
import { fileUplode } from "../../Utils/HttpClient";
import { useNavigate } from "react-router-dom";
import Layout from "../../Layout/DashboardLayout/Index";
import { Link } from "react-router-dom";

export default function CreateBanner() {
  const [banner, setBanner] = useState("");
  const [bannerLine, setBannerLine] = useState("");
  const [uploadImage, setUploadImage] = useState("");

  const navigate = useNavigate();

  const handleBanner = (e) => {
    setBanner(e.target.files[0]);
    setUploadImage(URL.createObjectURL(e.target.files[0]));
  };

  const submitBanner = async () => {
    //   alert();
    if (banner !== "") {
      let data = new FormData();
      data.append("bannerimg", banner);
      data.append("banner_line", bannerLine);
      // console.log(bannerLine)

      let result = await fileUplode("admin/addbanner", "POST", data);
      // console.log(result)
      if (result && result.status) {
        toast("Banners Added Successfully!", {
          position: "bottom-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setTimeout(function () {
          navigate("/banner");
        }, 3000);
      } else {
        toast("Server Busy! Try again after sometime", {
          position: "bottom-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } else {
      toast("All Fields are Required", {
        position: "bottom-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  return (
    <Layout sidebar>
      <main className="enrollment">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-4 col-12">
              <div className="description">
                <h4 className="text-base mb-2">Description</h4>
                <p className="text-body ">
                  Add your banner details and necessary information here
                </p>
              </div>
            </div>
            <div className="col-xl-8 col-lg-8 col-md-8 col-12 createCategoryCustom">
              <div className="form-group">
                <label>Banner Link</label>
                <input
                  type="text"
                  placeholder="Enter Banner Link"
                  className="form-control"
                  style={{ width: "100%" }}
                  value={bannerLine}
                  onChange={(e) => setBannerLine(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label>Image</label>
                <input
                  type="file"
                  id="myfile"
                  name="myfile"
                  className="form-control"
                  style={{ width: "100%" }}
                  onChange={handleBanner}
                />
              </div>
              <div className="col-md-12 my-2">
                <img src={uploadImage} height="100px" widtth="100px" />
              </div>
            </div>
          </div>

          <div className="row mt-5">
          <div className="col-xl-8 col-lg-8 col-12 col-md-9" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          <Link to={"/banner"}>
              <button type="button" href="#" className="btn btn-addCategory ">
                Back
              </button>
            </Link>
          </div>
            <div className="col-xl-4 col-lg-4 col-12 col-md-3">
              <button
                onClick={submitBanner}
                href="#"
                className="btn btn-addCategory "
              >
                Add Banner
              </button>
            </div>
          </div>
        </div>
      </main>
    </Layout>
  );
}
