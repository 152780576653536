import React, { useEffect, useState } from "react";
import SearchableDropdown from "../../Component/SearchableDropdown/SearchableDropdown";
import { requestData } from "../../Utils/HttpClient";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { type } from "@testing-library/user-event/dist/type";
import { useSearchParams } from "react-router-dom";
import Layout from "../../Layout/DashboardLayout/Index";
import { ColorRing } from "react-loader-spinner";
import { Link } from "react-router-dom";

export default function EditCategory() {
  const [catType, setCatType] = useState("");
  const [menuName, setMenuName] = useState("");
  const [getAllType, setGetAllType] = useState([]);
  const [getAllMenu, setgetAllMenu] = useState([]);
  const [category, setCategory] = useState("");
  const navigate = useNavigate();
  const [params] = useSearchParams();

  const [loader, setLoader] = useState(true);

  useEffect(() => {
    fetchMenu();
  }, []);

  const fetchMenu = async () => {
    let result = await requestData("admin/menufache", "POST");
    // console.log(result.data)
    if (result && result.status) {
      // console.log("Type", result.data);
      setgetAllMenu(result.data);
      // console.log(multiSelectArray);
    }
    fetchType();
  };

  const fetchType = async () => {
    let result = await requestData("admin/fetch_all_type", "POST");
    // console.log(result.data)
    if (result && result.status) {
      // console.log("Type", result.data);
      setGetAllType(result.data);
      // console.log(multiSelectArray);
    }
    FilterCategory();
  };

  const FilterCategory = async () => {
    let id = params.get("catId");
    // console.log(id);
    let result = await requestData("admin/fetch_category", "POST");
    if (result && result.status) {
      // console.log(result);
      let arr =
        result.data.length > 0 &&
        result.data.filter((it) => it.id === parseInt(id));
      // console.log(arr);
      if (arr.length > 0) {
        // console.log(arr);
        setLoader(false);
        setCategory(arr[0].Category_name);
        setCatType(arr[0].Product_typeid);
        setMenuName(arr[0].menu_name);
      }
    }
  };

  const editCategory = async () => {
    if (catType !== "" && category !== "" && menuName !== "") {
      let id = params.get("catId");
      let dataSend = {
        cat_id: id,
        type: [catType],
        Category_name: category,
        menu_name: menuName,
      };
      console.log(dataSend);
      let res = await requestData("admin/edit_category", "POST", dataSend);
      if (res && res.status) {
        toast("Category edited Successfully!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setTimeout(function () {
          navigate("/categories");
        }, 2000);
      } else {
        toast("Category already exists! Try a new one...", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } else {
      toast("All Fields are Required", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };
  return (
    <Layout sidebar>
      <ToastContainer />
      {/* {console.log(catType)} */}
      <main className="enrollment">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-4 col-12">
              <div className="description">
                <h4 className="text-base mb-2">Description</h4>
                <p className="text-body ">
                  Edit your category details and necessary information from here
                </p>
              </div>
            </div>
            <div className="col-xl-8 col-lg-8 col-md-8 col-12 createCategoryCustom">
              <div className="form-group">
                <label>Menu</label>
                {/* <SearchableDropdown data={data} /> */}
                <div className="row">
                  <div className="col-md-12">
                    <select
                      className="form-control"
                      id="exampleFormControlSelect1"
                      value={menuName}
                      onChange={(e) => setMenuName(e.target.value)}
                    >
                      <option value="">Select Menu</option>
                      {getAllMenu.map((item, index) => {
                        return (
                          <option value={item.id} key={index}>
                            {item.menu_name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label>Type</label>
                {/* <SearchableDropdown data={data} /> */}
                <div className="row">
                  <div className="col-md-12">
                    <select
                      className="form-control"
                      id="exampleFormControlSelect1"
                      value={catType}
                      onChange={(e) => setCatType(e.target.value)}
                    >
                      <option value="">Select Category</option>
                      {getAllType.map((item, index) => {
                        return (
                          <option value={item.id} key={index}>
                            {item.producttype}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label>Category</label>
                <input
                  type="text"
                  placeholder="Enter Category Name"
                  value={category}
                  style={{ width: "100%" }}
                  onChange={(e) => setCategory(e.target.value)}
                />
              </div>
            </div>
          </div>

          <div className="row mt-5">
            <div className="col-xl-8 col-lg-8 col-12 col-md-8" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
              <Link to={"/categories"}>
                <button type="button" href="#" className="btn btn-addCategory ">
                  Back
                </button>
              </Link>
            </div>
            <div className="col-xl-4 col-lg-4 col-12 col-md-4">
                <button
                  type="button"
                  onClick={editCategory}
                  href="#"
                  className="btn btn-addCategory "
                >
                  Edit Category
                </button>
              </div>
          </div>
        </div>
      </main>
      {loader ? (
        <span
          style={{
            display: "flex",
            position: "fixed",
            zIndex: "1000",
            left: "0",
            top: "0",
            right: "0",
            margin: "0 auto",
            background: "rgba(0,0,0,0.3)",
            justifyContent: "center",
            height: "100vh",
            alignItems: "center",
          }}
        >
          <ColorRing
            visible={true}
            id="colorRing"
            height="80"
            width="80"
            ariaLabel="blocks-loading"
            wrapperStyle={{}}
            wrapperclassName="blocks-wrapper"
            colors={["#f21322", "#0cb3f0", "#f58716", "#f00cd9", "#d50cf0"]}
          />
        </span>
      ) : null}
    </Layout>
  );
}
