import React, { useMemo, useState } from 'react'

function SearchableDropdown({ data, parentCallback }) {
    console.log("SearchData", data);
    // console.log("SendData", sendData);
    const [query, setQuery] = useState("")

    const [listShow, setListShow] = useState(false)

    const filterData = useMemo(() => {
        return data.filter(item => item.name.includes(query))
    }, [query])

    const filterId = data.filter(item => item.name === query)
    if(filterId.length > 0){
        console.log("filterId",filterId);
        // setCatId(filterId[0].id)
        parentCallback(filterId[0].id)
    }
    return (
        <div className='customSearch' onClick={() => {
            setListShow(!listShow)
            // parentCallback(query)
        }}>{console.log("query", query)}
            <input type="text" placeholder='Filter by Group' value={query} onChange={(val) => {
                // setListShow(true)
                setQuery(val.target.value)
            }} className="form-control" />
            {listShow && <div className='customDropdown'>
                {filterData.length > 0 ? filterData.map((item) => { return <li onClick={()=>setQuery(item.name)}>{item.name}</li> }) : <li>no result found</li>}
            </div>}
        </div>
    )
}

export default SearchableDropdown