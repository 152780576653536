import React, { useEffect, useState } from "react";
import profile from "../../images/avatar-placeholder.svg";
import PaginatedItems from "../../Component/Pagination/Index";
import Layout from "../../Layout/DashboardLayout/Index";
import { PROFILE_IMG_URL, requestData } from "../../Utils/HttpClient";
import { toast } from "react-toastify";
import { ColorRing } from "react-loader-spinner";
import Pagination from "../../Component/Pagination/Pagination";

let successMsgStyle = {
  position: "bottom-right",
  autoClose: 1000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

export default function UserContact() {
  const [fetchUser, setFetchUser] = useState([]);
  const [status, setStatus] = useState("");
  const [postPerPage, setPostPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [userId, setUserId] = useState("");

  // Component did mount
  useEffect(() => {
    getAllUserContact();
  }, []);

  const lastPostIndex = currentPage * postPerPage;
  const firstPostIndex = lastPostIndex - postPerPage;
  const currentPost = fetchUser.slice(firstPostIndex, lastPostIndex);

  const getAllUserContact = async () => {
    let data = await requestData("admin/fetchContactDetails", "GET");
    // console.log("contacts", data.data);
    // return false
    if (data && data.status) {
      setFetchUser(data.data);
      console.log("AllUserContact", data.data);
    }
  };

  const handleDelete = async (id) => {
    let res = await requestData("admin/deleteContactDetails", "POST", {
      contact_id: id,
    });

    if (res && res.status) {
      getAllUserContact();
      toast.success("Info deleted successfully", successMsgStyle);
    } else {
      toast.error("Something went wrong.");
    }
  };

  return (
    <Layout sidebar>
      <main className="enrollment">
        <div className="container-fluid">
          {/* <div className="col-md-12 col-12 col-xl-12 col-lg-12 px-0" style={{ marginBottom: "20px" }}>
            <div className="categoryBox custCategoryWrap" style={{ display: "flex" }} >
              <div className="categoryLeft">
                <h1 className="text-heading">Customers Contact</h1>
              </div>
              <div className="formBtn">
                <div className="form-group has-search">
                  <span className="fa fa-search form-control-feedback" />
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Type your query and press enter"
                  />
                </div>
                <a href="#" className="btn btn-addCategory">
                  + Add
                </a>
              </div>
            </div>
          </div> */}

          <div className="users">
            <div className="card recentTable_Card">
              <div className="card-body p-0">
                <div className="table-responsive">
                  <table className="table table-hover mb-0">
                    <thead>
                      <tr>
                        <th className="text-center">Sl</th>
                        <th className="text-center">Name</th>
                        <th className="text-center">Email</th>
                        <th>Phone No</th>
                        {/* <th>Available wallet points</th> */}
                        <th>Message</th>
                        <th className="text-center">Delete</th>
                      </tr>
                    </thead>
                    {currentPost.length > 0 ? (
                      currentPost.map((item, index) => (
                        <tbody key={index}>
                          <tr>
                            <td className="text-center">{index + 1}</td>
                            <td className="text-center">{item.name}</td>
                            <td className="text-center">{item.email}</td>
                            <td className="text-center">{item.phone}</td>
                            {/* <td>5000</td> */}
                            <td>{item.msg}</td>
                            <td>
                              <div className="gap-8 inline-flex w-auto items-center">
                                <button
                                  className="text-red-500 transition duration-200 hover:text-red-600 focus:outline-none"
                                  title="Delete"
                                  onClick={() => handleDelete(item.id)}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 19.4 22.169"
                                    fill="currentColor"
                                    width={16}
                                  >
                                    <g
                                      fill="none"
                                      stroke="currentColor"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="1.4"
                                    >
                                      <path
                                        data-name="Rectangle 2"
                                        d="M8.238.7h2.923a2 2 0 012 2v.769h0-6.923 0V2.7a2 2 0 012-2z"
                                      />
                                      <path
                                        data-name="Line 1"
                                        d="M.7 3.469h18"
                                      />
                                      <path
                                        data-name="Path 77"
                                        d="M14.649 21.469h-9.9a1.385 1.385 0 01-1.38-1.279L2.085 3.469h15.231L16.029 20.19a1.385 1.385 0 01-1.38 1.279z"
                                      />
                                      <path
                                        data-name="Line 2"
                                        d="M7.623 6.238V18.7"
                                      />
                                      <path
                                        data-name="Line 3"
                                        d="M11.777 6.238V18.7"
                                      />
                                    </g>
                                  </svg>
                                </button>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      ))
                    ) : (
                      <span
                        style={{
                          display: "flex",
                          position: "fixed",
                          zIndex: "1000",
                          left: "0",
                          top: "0",
                          right: "0",
                          margin: "0 auto",
                          background: "rgba(0,0,0,0.3)",
                          justifyContent: "center",
                          height: "100vh",
                          alignItems: "center",
                        }}
                      >
                        <ColorRing
                          visible={true}
                          id="colorRing"
                          height="80"
                          width="80"
                          ariaLabel="blocks-loading"
                          wrapperStyle={{}}
                          wrapperclassName="blocks-wrapper"
                          colors={[
                            "#f21322",
                            "#0cb3f0",
                            "#f58716",
                            "#f00cd9",
                            "#d50cf0",
                          ]}
                        />
                      </span>
                    )}
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Pagination
          totalPosts={fetchUser.length}
          postPerPage={postPerPage}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
        />
        {/* <PaginatedItems itemsPerPage={4}/> */}
      </main>
    </Layout>
  );
}
