import React, { useState, useEffect } from "react";
import { requestData } from "../../Utils/HttpClient";
import moment from "moment/moment";
import PaginatedItems from "../../Component/Pagination/Index";
import { Link } from "react-router-dom";
import Pagination from "../../Component/Pagination/Pagination";
import { ColorRing } from "react-loader-spinner";
import Layout from "../../Layout/DashboardLayout/Index";

export default function Index() {
  const [exportBtn, setExportBtn] = useState(false);
  const [totalOrder, setTotalOrder] = useState([]);
  const [postPerPage, setPostPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [orderByInvoice, setOrderByInvoice] = useState("");
  const [getIndex, setGetIndex] = useState("");
  const [expandRecentOrder, setExpandRecentOrder] = useState(false);

  useEffect(() => {
    totalOrderDetail();
  }, []);

  const handleClickBtn = () => {
    setExportBtn(!exportBtn);
  };

  const totalOrderDetail = async () => {
    let data = await requestData("admin/Fetch_User_Order", "POST");
    if (data && data.status) {
      // console.log("TotalOrderInfo", data.data);
      setTotalOrder(data.data);
    }
  };

  const getOrderByInvoice = async (invoiceId) => {
    let dataSend = {
      invoice_id: invoiceId,
    };
    let result = await requestData(
      "admin/Fetch_User_Order_List",
      "POST",
      dataSend
    );
    if (result && result.status) {
      setOrderByInvoice(result.data);
      // console.log("OrderByInvoice...", result.data);
    }
  };

  const lastPostIndex = currentPage * postPerPage;
  const firstPostIndex = lastPostIndex - postPerPage;
  const currentPost = totalOrder.slice(firstPostIndex, lastPostIndex);

  return (
    <Layout sidebar>
    {/* {console.log("CurrentPost...", currentPost)} */}
      <main className="enrollment">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 col-12 col-xl-12 col-lg-12">
              <div
                className=" custCategoryWrap OrderBox"
                style={{ display: "flex" }}
              >
                <div className="OrderLeft">
                  <h1 className="text-heading" style={{ marginBottom: "0" }}>
                    Orders
                  </h1>
                </div>
                <div className=" Orderbtn">
                  <div className="form-group has-search">
                    <span className="fa fa-search form-control-feedback invisible" />
                    <input
                      type="text"
                      className="form-control invisible"
                      placeholder="Type your query and press enter"
                    />
                  </div>
                  <a
                    href="#"
                    style={{ marginLeft: "15px" }}
                    onClick={handleClickBtn}
                    className="invisible"
                  >
                    <i className="fa-solid fa-ellipsis-vertical"></i>
                  </a>
                </div>
              </div>
              {exportBtn && (
                <div>
                  <button className="OrderExportBtn">
                    {/* <i className="fa-solid fa-cloud-arrow-up"></i>Exports Orders */}
                    <label className="form_label">
                      <i className="fa-solid fa-cloud-arrow-up"></i>
                      <input
                        type="file"
                        className="form-control"
                        id="uploadFile"
                      />
                      Exports Orders
                    </label>
                  </button>
                </div>
              )}
            </div>
            <div
              className="col-md-12 col-12 col-xl-12 col-lg-12"
              style={{ marginTop: 40 }}
            >
              <div className="card recentTable_Card">
                <div className="card-header">
                  <h3 className="">Recent Orders</h3>
                </div>
                <div className="card-body p-0">
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th />
                          <th className="text-center">Invoice Id</th>
                          <th className="text-center">Delivery Fee </th>
                          <th className="text-center">Total</th>
                          <th className="text-center">Order Date</th>
                          {/* <th className="text-left">Status</th> */}
                          <th className="text-center">Action</th>
                        </tr>
                      </thead>
                      {currentPost.length > 0 ?
                        currentPost.map((item, index) => (
                          <tbody key={index}>
                            <tr>
                              <td>
                                <i
                                  onClick={() => {
                                    setGetIndex(index);
                                    getOrderByInvoice(item.invoice_id);
                                    setExpandRecentOrder(!expandRecentOrder);
                                    // setInvoiceId(item.invoice_id)
                                  }}
                                  className="fa-solid fa-plus toggle_Icons"
                                />
                              </td>
                              <td>{item.invoice_id}</td>
                              <td>₹ 0.00</td>
                              <td className="text-center">
                                {item.total_price}
                              </td>
                              <td className="text-center">
                                {moment(item.created_at).fromNow()}
                              </td>
                              {/* <td className="text-left">
                                <span className="confirmed_BTN">
                                  {item.order_status}
                                </span>
                              </td> */}
                              <td>
                                <Link
                                  to={`/orderDetail?invoiceId=${item.invoice_id}&statusName=${item.order_status}`}
                                >
                                  <i className="fa-solid fa-eye" />
                                </Link>
                              </td>
                            </tr>
                            {expandRecentOrder &&
                              orderByInvoice &&
                              index === getIndex &&
                              orderByInvoice.user_order_product.length > 0 &&
                              orderByInvoice.user_order_product.map(
                                (item, index) => (
                                  <tr key={index}>
                                    <td></td>
                                    <td>{item.product_name.Product_title}</td>
                                    <td>₹ 0.00</td>
                                    <td>{item.total_price}</td>
                                    <td>Vendor</td>
                                    <td>Qty- {item.product_count}</td>
                                    <td></td>
                                  </tr>
                                )
                              )}
                          </tbody>
                        )) :
                        <span
                          style={{
                            display: "flex",
                            position: "fixed",
                            zIndex: "1000",
                            left: "0",
                            top: "0",
                            right: "0",
                            margin: "0 auto",
                            background: "rgba(0,0,0,0.3)",
                            justifyContent: "center",
                            height: "100vh",
                            alignItems: "center",
                          }}
                        >
                          <ColorRing
                            visible={true}
                            id="colorRing"
                            height="80"
                            width="80"
                            ariaLabel="blocks-loading"
                            wrapperStyle={{}}
                            wrapperclassName="blocks-wrapper"
                            colors={[
                              '#f21322', '#0cb3f0', '#f58716', '#f00cd9', '#d50cf0'
                            ]}
                          />
                        </span>
                        }
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <PaginatedItems itemsPerPage={4} /> */}
        <Pagination
          totalPosts={totalOrder.length}
          postPerPage={postPerPage}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
        />
      </main>
    </Layout>
  );
}
