import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

export default function Sidebar() {
  const [show, setShow] = useState(true);
  const location = useLocation() 
  console.log("Loc", location); 
  useEffect(() => {
    // window.addEventListener("")
    console.log("scre", window.innerWidth);
    if (window.innerWidth > 1024) {
      setShow(false);
    }
  }, []);

  return (
    <>
      <div className="wrapper DextopSideBar" id="togglesidebar">
        <ul className="sidebar-wrapper">
          <li className={`${location.pathname === '/' && 'active'}`}>
            <Link to="/">
              <i className={`fa-solid fa-house`}></i>Dashboard
            </Link>
          </li>
          {/* <li className={`${location.pathname === '/popupBanner' && 'active'}`}>
            <Link to="/popupBanner">
              <i className={`fa-solid fa-house`}></i>Popup Banner
            </Link>
          </li> */}
          <li className={`${location.pathname == '/menu' && 'active'}`}>
            <Link to="/menu">
              <i className={`fa-solid fa-list`}></i>Header Menus
            </Link>
          </li>
          <li className={`${location.pathname == '/prodType' && 'active'}`}>
            <Link to="/prodType">
              <i className={`fa-solid fa-palette`}></i>Product Type
            </Link>
          </li>
          
          <li className={`${location.pathname == '/categories' && 'active'}`}>
            <Link to="/categories">
              <i className={`fa-solid fa-holly-berry`}></i>Header Categories
            </Link>
          </li>
          <li className={`${location.pathname == '/age' && 'active'}`}>
            <Link to="/age">
              {" "}
              <i className={`fa-brands fa-magento`}></i>Ages
            </Link>
          </li>
          <li className={`${location.pathname == '/subcategory' && 'active'}`}>
            <Link to="/subcategory">
              <i className="fa-solid fa-holly-berry"></i>Header Sub Categories
            </Link>
          </li>
          <li className={`${location.pathname == '/color' && 'active'}`}>
            <Link to="/color">
              <i className="fa-solid fa-palette"></i>Colours
            </Link>
          </li>
          <li className={`${location.pathname == '/vendor' && 'active'}`}>
            <Link to="/vendor">
              <i className="fa-brands fa-sellcast"></i>Vendors
            </Link>
          </li>
          <li className={`${location.pathname == '/brands' && 'active'}`}>
            <Link to="/brands">
              <i className="fa-solid fa-copyright"></i>Brands
            </Link>
          </li>
          <li className={`${location.pathname == '/banner' && 'active'}`}>
            <Link to="/banner">
              <i className="fa-solid fa-scroll"></i>Banners
            </Link>
          </li>
          <li className={`${location.pathname == '/products' && 'active'}`}>
            <Link to="/products">
              <i className="fa-brands fa-product-hunt"></i>Products
            </Link>
          </li>
          {/* <li className={`${location.pathname == '/bulkProduct' && 'active'}`}>
            <Link to="/bulkProduct">
              <i className="fa-brands fa-product-hunt"></i>Bulk Products
            </Link>
          </li> */}
          
          <li className={`${location.pathname == '/order' && 'active'}`}>
            <Link to="/order">
              <i className="fa-solid fa-square-check"></i>Orders
            </Link>
          </li>
          <li className={`${location.pathname == '/order-status' && 'active'}`}>
            <Link to="/order-status">
              <i className="fa-solid fa-gauge-simple-high"></i>Order Status
            </Link>
          </li>
          <li className={`${location.pathname == '/user' && 'active'}`}>
            <Link to="/user">
              <i className="fa fa-users" aria-hidden="true"></i>Users
            </Link>
          </li>
          <li className={`${location.pathname == '/userContact' && 'active'}`}>
            <Link to="/userContact">
              <i class="fa-solid fa-envelope"></i>Contact Us
            </Link>
          </li>
          <li className={`${location.pathname == '/partner' && 'active'}`}>
            <Link to="/partner">
            <i class="fa-solid fa-handshake"></i>Partner Contacts
            </Link>
          </li>
          {/* <li><Link to='/withdrawal'><i className="fa-solid fa-money-bill-transfer"></i>Withdrawals</Link></li> */}
          
          {/* <li><Link to='/review'><i className="fa-solid fa-hospital-user"></i>Reviews</Link></li> */}
          
          {/* <li><Link to='/questions'><i className="fa-solid fa-person-circle-question"></i>Questions</Link></li> */}
        </ul>
      </div>
    </>
  );
}
