import React, { useState } from 'react'
import { fileUplode } from '../../Utils/HttpClient'
import { toast } from "react-toastify";
import { useNavigate } from 'react-router-dom';
import Layout from '../../Layout/DashboardLayout/Index';
import { Link } from 'react-router-dom';

export default function CreateBrands() {

  const [brand, setBrand] = useState("")
  const [image, setImage] = useState("")
  const [uploadImage, setUploadImage] = useState("")

  const nagigate = useNavigate()

  const handleImage = e => {
    setImage(e.target.files[0])
    setUploadImage(URL.createObjectURL(e.target.files[0]))
  }


  const submitBrand = async () => {
    //   alert();
    if (brand !== "" && image.length !== "") {
      let form = new FormData()
      form.append('brand_name', brand)
      form.append('brand_image', image)

      let result = await fileUplode(
        "admin/add_brand",
        "POST",
        form
      )
        console.log("Brand", result)
      if (result && result.status) {
        toast("Brand created Successfully!", {
          position: "bottom-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
        setTimeout(function () {
          nagigate("/brands")
        }, 2000)
      } else {
        toast("Brand already exists! Try a new one...", {
          position: "bottom-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
      }
    } else {
      toast("All Fields are Required", {
        position: "bottom-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
  }
  return (
    <Layout sidebar>
      <main className="enrollment">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-4 col-12">
              <div className="description">
                <h4 className="text-base mb-2">Description</h4>
                <p className="text-body ">
                  Add your brand details and necessary information here
                </p>
              </div>
            </div>
            <div className="col-xl-8 col-lg-8 col-md-8 col-12 createCategoryCustom">
              <div className='form-group'>
                <label>Brand Name</label>
                <input
                  type="text"
                  placeholder='Enter Brand Name'
                  className='form-control'
                  style={{ width: "100%" }}
                  value={brand}
                  onChange={(e) => setBrand(e.target.value)}
                />
              </div>
              <div className='form-group'>
                <label>Image</label>
                <input
                  type="file"
                  id="myfile"
                  name="myfile"
                  className='form-control'
                  style={{ width: "100%" }}
                  onChange={handleImage}
                />
              </div>
              {uploadImage && <div className="col-md-12 my-2">
                <img src={uploadImage} height="100px" widtth="100px" />
              </div>
              }
            </div>
          </div>

          <div className="row mt-5">
          <div className="col-xl-8 col-lg-8 col-12 col-md-9" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          <Link to={"/brands"}>
              <button type="button" href="#" className="btn btn-addCategory ">
                Back
              </button>
            </Link>
          </div>
            <div className="col-xl-4 col-lg-4 col-12 col-md-3">
              <button onClick={submitBrand} href="#" className="btn btn-addCategory ">
                Add Brand
              </button>
            </div>
          </div>
        </div>
      </main>
    </Layout>
  )
}
