import React from 'react'
import q1 from "../../images/image05-thumbnail.jpg";
import PaginatedItems from "../../Component/Pagination/Index";
import Layout from '../../Layout/DashboardLayout/Index';

export default function Index() {
  return (
    <Layout sidebar>
      <main className="enrollment">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 col-12 col-xl-12 col-lg-12">
              <div className="reviewBox">
                <div className="reviewLeft">
                  <h1 className="text-heading">Questions</h1>
                </div>
              </div>
            </div>
            <div
              className="col-md-12 col-12 col-xl-12 col-lg-12"
              style={{ marginTop: 40 }}
            >
              <div className="card recentTable_Card">
                <div className="card-body p-0">
                  <div className="table-responsive">
                    <table className="table table-hover questions">
                      <thead>
                        <tr>
                          <th style={{ textAlign: "left" }}>Image</th>
                          <th style={{ textAlign: "left" }}>Question &amp; Answer</th>
                          <th style={{ textAlign: "left" }}>Customer Name</th>
                          <th style={{ textAlign: "left" }}>Product Name</th>
                          <th style={{ textAlign: "left" }}>Feedbacks</th>
                          <th style={{ textAlign: "left" }}>Date</th>
                          <th style={{ textAlign: "right" }}>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td style={{ textAlign: "left" }}>
                            <img src={q1} alt="" />
                          </td>
                          <td style={{ textAlign: "left" }}>
                            <div>
                              <h3 className="text mb-2 text-sm font-semibold text-heading">
                                <span className="me-1 inline-block uppercase">
                                  Q:
                                </span>
                                Is it gutenberg based or elementor based?{" "}
                              </h3>
                              <p className="text-sm">
                                <span className="me-1 inline-block font-semibold uppercase text-heading">
                                  A:
                                </span>
                                It is gutenberg based.
                              </p>
                            </div>
                          </td>
                          <td style={{ textAlign: "left" }}>customer 3</td>
                          <td style={{ textAlign: "left" }}>
                            <a href="#" className="products">
                              Shoppie UI Kit PSD E-Commerce-Designvorlage
                            </a>
                          </td>
                          <td style={{ textAlign: "left" }}>
                            <div className="d-flex items-center space-x-4">
                              <span className="flex items-center text-xs tracking-wider text-gray-400 transition">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 17.067 16"
                                  className="me-1.5 h-4 w-4"
                                >
                                  <g
                                    id="like_1_"
                                    data-name="like (1)"
                                    transform="translate(0 -0.75)"
                                    fill="currentColor"
                                  >
                                    <path
                                      id="Path_22672"
                                      data-name="Path 22672"
                                      d="M1.244,17.063H3.022a1.164,1.164,0,0,0,1.244-1.057V9.057A1.164,1.164,0,0,0,3.022,8H1.244A1.164,1.164,0,0,0,0,9.057v6.948A1.164,1.164,0,0,0,1.244,17.063Z"
                                      transform="translate(0 -1.25)"
                                    />
                                    <path
                                      id="Path_22673"
                                      data-name="Path 22673"
                                      d="M11.255.75c-.711,0-1.067.356-1.067,2.133,0,1.69-1.636,3.049-2.689,3.75v8.809a16.575,16.575,0,0,0,6.955,1.308h1.138a2.84,2.84,0,0,0,2.8-2.361l.8-4.622a2.843,2.843,0,0,0-2.8-3.328H13.033a6.867,6.867,0,0,0,.533-2.844A2.611,2.611,0,0,0,11.255.75Z"
                                      transform="translate(-2.167 0)"
                                    />
                                  </g>
                                </svg>
                                0
                              </span>
                              <span className="flex items-center text-xs tracking-wider text-gray-400 transition">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 17.067 16"
                                  className="me-1.5 h-4 w-4"
                                  style={{ marginLeft: 20 }}
                                >
                                  <path
                                    id="Path_22674"
                                    data-name="Path 22674"
                                    d="M19.244,1h1.778a1.166,1.166,0,0,1,1.244,1.065v7a1.166,1.166,0,0,1-1.244,1.065H19.244A1.166,1.166,0,0,1,18,9.06v-7A1.166,1.166,0,0,1,19.244,1Z"
                                    transform="translate(-18 0.188)"
                                    fill="currentColor"
                                  />
                                  <path
                                    id="Path_22675"
                                    data-name="Path 22675"
                                    d="M8.093.75a2.84,2.84,0,0,1,2.8,2.361l.8,4.622a2.843,2.843,0,0,1-2.8,3.328H5.533a6.867,6.867,0,0,1,.533,2.844A2.611,2.611,0,0,1,3.755,16.75c-.711,0-1.067-.356-1.067-2.133,0-1.69-1.636-3.049-2.689-3.75V2.058A16.575,16.575,0,0,1,6.955.75Z"
                                    transform="translate(5.333 -0.75)"
                                    fill="currentColor"
                                  />
                                </svg>
                                0
                              </span>
                            </div>
                          </td>
                          <td style={{ textAlign: "left" }}>2 months ago</td>
                          <td style={{ textAlign: "right" }}>
                            <div className="gap-8 inline-flex w-auto items-center">
                              <button
                                className="text-red-500 transition duration-200 hover:text-red-600 focus:outline-none"
                                title="Delete"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 19.4 22.169"
                                  fill="currentColor"
                                  width={16}
                                >
                                  <g
                                    fill="none"
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="1.4"
                                  >
                                    <path
                                      data-name="Rectangle 2"
                                      d="M8.238.7h2.923a2 2 0 012 2v.769h0-6.923 0V2.7a2 2 0 012-2z"
                                    />
                                    <path data-name="Line 1" d="M.7 3.469h18" />
                                    <path
                                      data-name="Path 77"
                                      d="M14.649 21.469h-9.9a1.385 1.385 0 01-1.38-1.279L2.085 3.469h15.231L16.029 20.19a1.385 1.385 0 01-1.38 1.279z"
                                    />
                                    <path data-name="Line 2" d="M7.623 6.238V18.7" />
                                    <path data-name="Line 3" d="M11.777 6.238V18.7" />
                                  </g>
                                </svg>
                              </button>
                              <a
                                className="text-base transition duration-200 hover:text-heading"
                                href="/categories/free/edit"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 20.547 20.299"
                                  fill="currentColor"
                                  width={16}
                                >
                                  <g stroke="currentColor" strokeWidth=".4">
                                    <path
                                      data-name="Path 78"
                                      d="M18.659 12.688a.5.5 0 00-.5.5v4.423a1.5 1.5 0 01-1.494 1.494H2.691A1.5 1.5 0 011.2 17.609V4.629a1.5 1.5 0 011.494-1.494h4.419a.5.5 0 100-1H2.691A2.493 2.493 0 00.2 4.629v12.98A2.493 2.493 0 002.691 20.1h13.976a2.493 2.493 0 002.491-2.491v-4.423a.5.5 0 00-.5-.5zm0 0"
                                    />
                                    <path
                                      data-name="Path 79"
                                      d="M18.96.856a2.241 2.241 0 00-3.17 0L6.899 9.739a.5.5 0 00-.128.219l-1.169 4.219a.5.5 0 00.613.613l4.219-1.169a.5.5 0 00.219-.128l8.886-8.887a2.244 2.244 0 000-3.17zm-10.971 9.21l7.273-7.273 2.346 2.346-7.273 7.273zm-.469.94l1.879 1.875-2.592.718zm11.32-7.1l-.528.528-2.346-2.345.528-.528a1.245 1.245 0 011.761 0l.585.584a1.247 1.247 0 010 1.761zm0 0"
                                    />
                                  </g>
                                </svg>
                              </a>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <PaginatedItems itemsPerPage={4}/> */}
      </main>
    </Layout>
  )
}
