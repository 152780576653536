import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { requestData } from "../../Utils/HttpClient";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PaginatedItems from "../../Component/Pagination/Index";
import { ColorRing } from "react-loader-spinner";
import Pagination from "../../Component/Pagination/Pagination";
import Layout from "../../Layout/DashboardLayout/Index";

export default function BulkProduct() {
  //   const [getAge, setGetAge] = useState([]);
  const [bulkFile, setBulkFile] = useState("");
  //   const [editId, setEditId] = useState("");
  //   const [postPerPage, setPostPerPage] = useState(10)
  //   const [currentPage, setCurrentPage] = useState(1)

  //   useEffect(() => {
  //     getAllAge();
  //   }, []);

  //   useEffect(() => {
  //     FilterAge();
  //   }, [editId]);

  //   const getAllAge = async () => {
  //     let result = await requestData("admin/fetchallage", "POST");
  //     if (result && result.status) {
  //       setGetAge(result.data);
  //       // console.log("AllAge", result.data);
  //     }
  //   };

  //   const lastPostIndex = currentPage * postPerPage
  //   const firstPostIndex = lastPostIndex - postPerPage
  //   const currentPost = getAge.slice(firstPostIndex, lastPostIndex)

  //   const FilterAge = async () => {
  //     let result = await requestData("admin/fetchallage", "POST");
  //     if (result && result.status) {
  //       let arr =
  //         result.data.length > 0 && result.data.filter((it) => it.id === editId);
  //       if (arr.length > 0) {
  //         // console.log(arr);
  //         setAge(arr[0].age_duration);
  //       }
  //     }
  //   };

  //   const handelDelete = async (id) => {
  //     if (window.confirm("Do you want to Delete Age?") === true) {
  //       if (id !== "") {
  //         // console.log(id)
  //         let dataSend = {
  //           ageid: id.toString(),
  //         };
  //         let res = await requestData("admin/deleteage", "POST", dataSend);
  //         if (res && res.status) {
  //           toast("Age Deleted Successfully!", {
  //             position: "bottom-right",
  //             autoClose: 1000,
  //             hideProgressBar: false,
  //             closeOnClick: true,
  //             pauseOnHover: true,
  //             draggable: true,
  //             progress: undefined,
  //           });
  //           getAllAge();
  //         } else {
  //           toast("Server Busy! Try again after sometime", {
  //             position: "bottom-right",
  //             autoClose: 1000,
  //             hideProgressBar: false,
  //             closeOnClick: true,
  //             pauseOnHover: true,
  //             draggable: true,
  //             progress: undefined,
  //           });
  //         }
  //       } else {
  //         toast("All Fields Required", {
  //           position: "bottom-right",
  //           autoClose: 1000,
  //           hideProgressBar: false,
  //           closeOnClick: true,
  //           pauseOnHover: true,
  //           draggable: true,
  //           progress: undefined,
  //         });
  //       }
  //     }
  //   };

  //   const handleUpdateAge = async () => {
  //     // console.log("AgeId", id)
  //     if (age !== "") {
  //       let dataSend = {
  //         ageid: editId,
  //         age: age,
  //       };
  //       // console.log(dataSend)
  //       // return false
  //       let result = await requestData("admin/editage", "POST", dataSend);
  //       // console.log(result)
  //       if (result && result.status) {
  //         toast("Age Updated Successfully!", {
  //           position: "bottom-right",
  //           autoClose: 1000,
  //           hideProgressBar: false,
  //           closeOnClick: true,
  //           pauseOnHover: true,
  //           draggable: true,
  //           progress: undefined,
  //         });
  //         getAllAge();
  //         setEditId("");
  //         setAge("");
  //         // setTimeout(function () {
  //         //   history.push("/age")
  //         // }, 2000)
  //       } else {
  //         toast("Server Busy! Try again after sometime", {
  //           position: "bottom-right",
  //           autoClose: 1000,
  //           hideProgressBar: false,
  //           closeOnClick: true,
  //           pauseOnHover: true,
  //           draggable: true,
  //           progress: undefined,
  //         });
  //       }
  //     } else {
  //       toast("All Fields Required", {
  //         position: "bottom-right",
  //         autoClose: 1000,
  //         hideProgressBar: false,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: true,
  //         progress: undefined,
  //       });
  //     }
  //   };

  const submitBulkProduct = async () => {
    if (bulkFile !== "") {
      let data = new FormData();
      data.append("image", bulkFile);
      // console.log(data)
      // return false
      let result = await requestData("admin/addage", "POST", data);
      // console.log(result)
      if (result && result.status) {
        toast("file Uploaded Successfully!", {
          position: "bottom-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        // getAllAge();
      } else {
        toast(result.message, {
          position: "bottom-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } else {
      toast("All Fields are Required", {
        position: "bottom-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  return (
    <Layout sidebar>
      <main className="enrollment">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 col-12 col-xl-12 col-lg-12">
              <div className="AgeBox custAgeWrap" style={{ display: "flex" }}>
                <div className="AgeLeft">
                  <h1 className="text-heading">Add Bulk Product</h1>
                </div>
                <div className="AgeformBtn">
                  <div className="form-group has-search">
                    <input
                      type="file"
                      name="ImportExcel"
                      title="Select File"
                      id="importData"
                      accept=".xls,.xlsx"
                      onChange={(e) => {
                        setBulkFile(e.target.files[0]);
                        console.log(e.target.files[0]);
                      }}
                    />
                  </div>
                  <button onClick={submitBulkProduct} className="btn btn-addCategory">
                    Upload
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </Layout>
  );
}
