// console.log(recentFiveOrder);
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { requestData } from "../../Utils/HttpClient";
import moment from "moment/moment";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { ColorRing } from "react-loader-spinner";

// import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: false,
      text: "Chart.js Bar Chart",
    },
  },
};

export default function Dashboard() {
  const [getAllOrderDetail, setGetAllOrderDetail] = useState("");
  const [totalOrder, setTotalOrder] = useState([]);
  const [hotProduct, sethotProduct] = useState([]);
  const [revenue, setRevenue] = useState([]);
  const [orderByInvoice, setOrderByInvoice] = useState("");
  const [getIndex, setGetIndex] = useState("")
  const [expandRecentOrder, setExpandRecentOrder] = useState(false);
  

  useEffect(() => {
    getAllOrder();
  }, []);

  const getAllOrder = async () => {
    let res = await requestData("admin/TotalOrderDetailsFetch", "POST");
    if (res && res.status) {
      console.log("GetOrderDetail", res.data);
      setGetAllOrderDetail(res.data);
    }
    totalOrderDetail();
    hotProductDetail();
    fetchRevenue();
  };

  const totalOrderDetail = async () => {
    let data = await requestData("admin/Fetch_User_Order", "POST");
    if (data && data.status) {
      let filterOrder = data.data.filter(item => item.order_status === 'Confirmed')
      // console.log("TotalOrderInfo", filterOrder);
      setTotalOrder(data.data);
    }
  };

  const hotProductDetail = async () => {
    let res = await requestData("admin/fetchproductbyhotdeal", "POST");
    if (res && res.status) {
      sethotProduct(res.data);
      // console.log("Hotproduct", res.data);
    }
  };

  const fetchRevenue = async () => {
    let result = await requestData("admin/revenue_generated", "POST");
    if (result && result.status) {
      var incomes = [];
      let income = result.data.total_income.split(",");
      income.forEach((element) => {
        incomes.push(element);
      });
      setRevenue(incomes);
      // setshowLoder(false);
    }
  };

  const getOrderByInvoice = async (invoiceId) => {
    let dataSend = {
      invoice_id: invoiceId,
    };
    let result = await requestData(
      "admin/Fetch_User_Order_List",
      "POST",
      dataSend
    );
    if (result && result.status) {
      setOrderByInvoice(result.data);
      console.log("OrderByInvoice...", result.data);
    }
  };

  const labels = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const data = {
    labels,
    datasets: [
      {
        label: "Revenue",
        data: revenue.map((item) => item),
        backgroundColor: "rgba(3, 211, 181, 0.85)",
      },
      // {
      //   label: 'Dataset 2',
      //   data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
      //   backgroundColor: 'rgba(53, 162, 235, 0.5)',
      // },
    ],
  };

  // if (showLoder === true) {
  //   document.body.style.opacity = "0.2";
  // } else {
  //   document.body.style.opacity = "1";
  // }

  const recentTenOrder = totalOrder.slice(0, 10);
  const recentTenHotProduct = hotProduct.slice(0, 10);

  return (
    <>
      <main className="enrollment">
        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-md-6 col-lg-6 col-xl-3 col-12 mb-4">
              <div className="bg_white">
                <div className="d-flex align-items-start justify-content-between total_Revenue_Main">
                  <div className="total_Revenue">
                    <h3>Total Revenue</h3>
                    <span>(Last 30 Days)</span>
                  </div>
                  <div className="dashboard_DollerSign">
                    <i className="fa-solid fa-dollar-sign" />
                  </div>
                </div>
                <span className="mb-2 total_Amount">
                  ₹ {getAllOrderDetail.total_revenue}
                </span>
              </div>
            </div>
            <div className="col-md-6 col-lg-6 col-xl-3 col-12 mb-4">
              <div className="bg_white">
                <div className="d-flex align-items-start justify-content-between total_Revenue_Main">
                  <div className="total_Revenue">
                    <h3>Total Product</h3>
                    {/* <span>(Last 30 Days)</span> */}
                  </div>
                  <div className="dashboard_DollerSign">
                  <i class="fa-brands fa-product-hunt"></i>
                    {/* <i className="fa-solid fa-dollar-sign" /> */}
                  </div>
                </div>
                <span className="mb-2 total_Amount">
                  ₹ {getAllOrderDetail.total_product}
                </span>
              </div>
            </div>
            <div className="col-md-6 col-lg-6 col-xl-3 col-12 mb-4">
              <div className="bg_white">
                <div className="d-flex align-items-start justify-content-between total_Revenue_Main">
                  <div className="total_Revenue">
                    <h3>Total Order</h3>
                    <span>(Last 30 Days)</span>
                  </div>
                  <div className="dashboard_DollerSign2">
                    <i className="fa-sharp fa-solid fa-cart-shopping" />
                  </div>
                </div>
                <span className="mb-2 total_Amount">
                  {getAllOrderDetail.total_order}
                </span>
              </div>
            </div>
            <div className="col-md-6 col-lg-6 col-xl-3 col-12 mb-4">
              <div className="bg_white">
                <div className="d-flex align-items-start justify-content-between total_Revenue_Main">
                  <div className="total_Revenue">
                    <h3>Active Vendor</h3>
                    {/* <span>(Last 30 Days)</span> */}
                  </div>
                  <div className="dashboard_DollerSign2">
                    <i className="fa-sharp fa-solid fa-cart-shopping" />
                  </div>
                </div>
                <span className="mb-2 total_Amount">
                  {getAllOrderDetail.total_active_vendor}
                </span>
              </div>
            </div>
            <div className="col-md-6 col-lg-6 col-xl-3 col-12 mb-4">
              <div className="bg_white">
                <div className="d-flex align-items-start justify-content-between total_Revenue_Main">
                  <div className="total_Revenue">
                    <h3>Recent Order</h3>
                     <span>(In 1 days)</span>
                  </div>
                  <div className="dashboard_DollerSign3">
                    <i className="fa-solid fa-circle-dollar-to-slot" />
                  </div>
                </div>
                <span className="mb-2 total_Amount">
                  {getAllOrderDetail.total_recent_order}
                </span>
              </div>
            </div>
            <div className="col-md-6 col-lg-6 col-xl-3 col-12 mb-4">
              <div className="bg_white">
                <div className="d-flex align-items-start justify-content-between total_Revenue_Main">
                  <div className="total_Revenue">
                    <h3>Recent Reviews</h3>
                  </div>
                  <div className="dashboard_DollerSign4">
                    <i className="fa-solid fa-shop" />
                  </div>
                </div>
                <span className="mb-2 total_Amount">
                  {getAllOrderDetail.total_recent_rating}
                </span>
              </div>
            </div>
          </div>
          <div className="BarGraph">
            <h3>Revenue History</h3>
            <div
              className="row "
            >
              {revenue.length > 0 ? (
                <Bar options={options} data={data} />
              ) : (
                <span style={{
                  display: "flex",
                  position: "fixed",
                  zIndex: "1000",
                  left: "0",
                  top: "0",
                  right: "0",
                  margin: "0 auto",
                  background: "rgba(0,0,0,0.3)",
                  justifyContent: "center",
                  height: "100vh",
                  alignItems: "center",
                }}>
                <ColorRing
                  visible={true}
                  id="colorRing"
                  height="80"
                  width="80"
                  ariaLabel="blocks-loading"
                  wrapperStyle={{}}
                  wrapperclassName="blocks-wrapper"
                  colors={[
                    '#f21322', '#0cb3f0', '#f58716', '#f00cd9', '#d50cf0'
                  ]}
                />
                </span>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-lg-12 col-md-12 col-12 mb-4">
              <div className="card recentTable_Card">
                <div className="card-header">
                  <h3 className="">Recent Orders</h3>
                </div>
                <div className="card-body p-0">
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th />
                          <th style={{ textAlign: "center" }}>Invoice Id</th>
                          <th style={{ textAlign: "center" }}>Total Price</th>
                          <th style={{ textAlign: "center" }}>Order Date</th>
                          {/* <th style={{ textAlign: "center" }}>Status</th> */}
                        </tr>
                      </thead>
                      {recentTenOrder.length > 0 &&
                        recentTenOrder.map((item, index) => (
                          <tbody key={index}>
                            <tr>
                              <td>
                                <i
                                  onClick={() => {
                                    setGetIndex(index)
                                    getOrderByInvoice(item.invoice_id);
                                    setExpandRecentOrder(!expandRecentOrder);
                                    // setInvoiceId(item.invoice_id)
                                  }}
                                  className="fa-solid fa-plus toggle_Icons"
                                />
                              </td>
                              <td>{item.invoice_id}</td>
                              <td> ₹{item.total_price}</td>
                              <td>{moment(item.created_at).fromNow()}</td>
                              {/* <td>
                                <span className="confirmed_BTN">
                                  {item.order_status}
                                </span>
                              </td> */}
                            </tr>
                            {expandRecentOrder &&
                              orderByInvoice && index === getIndex &&
                              orderByInvoice.user_order_product.length > 0 && 
                              orderByInvoice.user_order_product.map((item, index) => (
                                <tr key={index}>
                                  <td></td>
                                  <td>{item.product_name.Product_title}</td>
                                  <td>{item.total_price}</td>
                                  <td>{item.vendor && item.vendor.name}</td>
                                  <td>Qty- {item.product_count}</td>
                                </tr>
                              ))
                              }
                            {/* <tr>
                            <td>
                              <i className="fa-solid fa-plus toggle_Icons" />
                            </td>
                            <td>iADY6nXTvFTo</td>
                            <td>€8.39</td>
                            <td>2 months ago</td>
                            <td>
                              <span className="confirmed_BTN">Confirmed</span>
                            </td>
                          </tr> */}
                          </tbody>
                        ))}
                    </table>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="col-xl-6 col-lg-12 col-md-12 col-12  mb-4 withdrawBox recentWithdrawalsBox">
              <div className="card recentTable_Card">
                <div className="card-header">
                  <h3 className="">Recent Withdrawals</h3>
                </div>
                <div className="card-body p-0">
                  <div className="table-responsive">
                    <table className="table table-hover" cellPadding={10}>
                      <thead>
                        <tr>
                          <th className="text-left firsT_Column">Shop Name</th>
                          <th>Amount</th>
                          <th style={{ textAlign: "center" }}>Status</th>
                          <th style={{ textAlign: "center" }}>Created</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="text-left">Temper studios</td>
                          <td>€186.00</td>
                          <td>
                            <span className="pending_status">Pending</span>
                          </td>
                          <td>8 months ago</td>
                          <td>
                            <Link to="/withdrawals">
                              {" "}
                              <i className="fa-solid fa-eye" />
                            </Link>
                          </td>
                        </tr>
                        <tr>
                          <td className="text-left">Temper studios</td>
                          <td>€186.00</td>
                          <td>
                            <span className="approved_Status">Approved</span>
                          </td>
                          <td>8 months ago</td>
                          <td>
                            <Link to="/withdrawals">
                              {" "}
                              <i className="fa-solid fa-eye" />
                            </Link>
                          </td>
                        </tr>
                        <tr>
                          <td className="text-left">Temper studios</td>
                          <td>€186.00</td>
                          <td>
                            <span className="on_HoldStatus">On hold</span>
                          </td>
                          <td>8 months ago</td>
                          <td>
                            <i className="fa-solid fa-eye" />
                          </td>
                        </tr>
                        <tr>
                          <td className="text-left">Temper studios</td>
                          <td>€186.00</td>
                          <td>
                            <span className="processing_Status">
                              Processing
                            </span>
                          </td>
                          <td>8 months ago</td>
                          <td>
                            <i className="fa-solid fa-eye" />
                          </td>
                        </tr>
                        <tr>
                          <td className="text-left">Temper studios</td>
                          <td>€186.00</td>
                          <td>
                            <span className="reject_Status">Rejected</span>
                          </td>
                          <td>8 months ago</td>
                          <td>
                            <i className="fa-solid fa-eye" />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
          <div className="row">
            <div className="col-12 mb-4">
              <div className="card recentTable_Card">
                <div className="card-header">
                  <h3>Best Selling Products</h3>
                </div>
                <div className="card-body p-0">
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th className="text-center">Sl No</th>
                          <th className="text-left">Name</th>
                          {/* <th className="text-center">Layouts Type</th> */}
                          <th className="text-center">Shop</th>
                          <th className="text-right">Price/Unit</th>
                          <th className="text-center">Quantity</th>
                        </tr>
                      </thead>
                      {recentTenHotProduct.length > 0 &&
                        recentTenHotProduct.map((item, index) => (
                          <tbody key={index}>
                            <tr>
                              <td className="text-center">{index + 1}</td>
                              <td className="text-left">
                                {item.Product_title}
                              </td>
                              {/* <td className="text-center">Responsive</td> */}
                              <td className="text-center">
                                {item.vendorname ? item.vendorname.name : null}
                              </td>
                              <td className="text-right">
                                ₹ {item.selling_price}
                              </td>
                              <td className="text-center">
                                {item.stock?.stock}
                              </td>
                            </tr>
                          </tbody>
                        ))}
                    </table>
                  </div>
                </div>
              </div>
            </div>
          <div className="col-xl-6 col-lg-12 col-md-12 col-12  mb-4 withdrawBox recentWithdrawalsBox">
              <div className="card recentTable_Card">
                <div className="card-header">
                  <h3 className="">Recent Withdrawals</h3>
                </div>
                <div className="card-body p-0">
                  <div className="table-responsive">
                    <table className="table table-hover" cellPadding={10}>
                      <thead>
                        <tr>
                          <th className="text-left firsT_Column">Shop Name</th>
                          <th>Amount</th>
                          <th style={{ textAlign: "center" }}>Status</th>
                          <th style={{ textAlign: "center" }}>Created</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="text-left">Temper studios</td>
                          <td>€186.00</td>
                          <td>
                            <span className="pending_status">Pending</span>
                          </td>
                          <td>8 months ago</td>
                          <td>
                            <Link to="/withdrawals">
                              {" "}
                              <i className="fa-solid fa-eye" />
                            </Link>
                          </td>
                        </tr>
                        <tr>
                          <td className="text-left">Temper studios</td>
                          <td>€186.00</td>
                          <td>
                            <span className="approved_Status">Approved</span>
                          </td>
                          <td>8 months ago</td>
                          <td>
                            <Link to="/withdrawals">
                              {" "}
                              <i className="fa-solid fa-eye" />
                            </Link>
                          </td>
                        </tr>
                        <tr>
                          <td className="text-left">Temper studios</td>
                          <td>€186.00</td>
                          <td>
                            <span className="on_HoldStatus">On hold</span>
                          </td>
                          <td>8 months ago</td>
                          <td>
                            <i className="fa-solid fa-eye" />
                          </td>
                        </tr>
                        <tr>
                          <td className="text-left">Temper studios</td>
                          <td>€186.00</td>
                          <td>
                            <span className="processing_Status">
                              Processing
                            </span>
                          </td>
                          <td>8 months ago</td>
                          <td>
                            <i className="fa-solid fa-eye" />
                          </td>
                        </tr>
                        <tr>
                          <td className="text-left">Temper studios</td>
                          <td>€186.00</td>
                          <td>
                            <span className="reject_Status">Rejected</span>
                          </td>
                          <td>8 months ago</td>
                          <td>
                            <i className="fa-solid fa-eye" />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
