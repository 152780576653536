import React from 'react'
import { FaAngleDoubleLeft, FaAngleDoubleRight } from "react-icons/fa";

function Pagination({ totalPosts, postPerPage, setCurrentPage, currentPage }) {
    let pages = [];
    for (let i = 1; i <= Math.ceil(totalPosts / postPerPage); i++) {
        pages.push(i)
    }
    return (
        <div className='text-right mr-3 mt-3'>
            {currentPage === 1 ? <button disabled style={{ backgroundColor: '#098255', opacity: '.7', border: 'none', padding: '10px', color: '#fff', borderRadius: '4px' }} onClick={() => setCurrentPage(pages[0])}><FaAngleDoubleLeft />Previous</button> : <button style={{ backgroundColor: '#098255', border: 'none', padding: '10px', color: '#fff', borderRadius: '4px' }} onClick={() => setCurrentPage(pages[0])}><FaAngleDoubleLeft />Previous</button>}
            {pages.map((page, index) => {
                return <button style={{ backgroundColor: '#098255', borderRadius: '4px', border: 'none', padding: '10px', color: '#fff', fontWeight: '600', fontSize: '15px' }} key={index} onClick={() => setCurrentPage(page)} className=
                    {`${page === currentPage ? 'active' : ''} mx-1`}
                >{page}</button>
            })}
            {currentPage === pages.length ? <button disabled style={{ backgroundColor: '#098255', opacity: '.7', padding: '10px', border: 'none', color: '#fff', borderRadius: '4px' }} onClick={() => setCurrentPage(pages.length)}>Next<FaAngleDoubleRight /></button> : <button style={{ backgroundColor: '#098255', padding: '10px', border: 'none', color: '#fff', borderRadius: '4px' }} onClick={() => setCurrentPage(pages.length)}>Next<FaAngleDoubleRight /></button>}
        </div>
    )
}

export default Pagination