import React, { useState } from "react";
import { fileUplode } from "../../Utils/HttpClient";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Layout from "../../Layout/DashboardLayout/Index";
import { Link } from "react-router-dom";

export default function CreateProductType() {
  const [productType, setProductType] = useState("");
  const [image, setImage] = useState("");
  const [uploadImage, setUploadImage] = useState("");

  const nagigate = useNavigate();

  const handleImage = (e) => {
    setImage(e.target.files[0]);
    setUploadImage(URL.createObjectURL(e.target.files[0]));
  };

  const submitProductType = async () => {
    //   alert();
    if (productType !== "" && image.length !== "") {
      let form = new FormData();
      form.append("product_type", productType);
      form.append("typeimg", image);

      let result = await fileUplode("admin/addProductType", "POST", form);
      console.log("ProductType", result);
      if (result && result.status) {
        toast("Product Type created Successfully!", {
          position: "bottom-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setTimeout(function () {
          nagigate("/prodType");
        }, 2000);
      } else {
        toast("Product Type already exists! Try a new one...", {
          position: "bottom-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } else {
      toast("All Fields are Required", {
        position: "bottom-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  return (
    <Layout sidebar>
      <main className="enrollment">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-4 col-12">
              <div className="description">
                <h4 className="text-base mb-2">Description</h4>
                <p className="text-body ">
                  Add your Product type details and necessary information
                  here
                </p>
              </div>
            </div>
            <div className="col-xl-8 col-lg-8 col-md-8 col-12 createCategoryCustom">
              <div className="form-group">
                <label>Product Type</label>
                <input
                  type="text"
                  placeholder="Enter Product Type"
                  className="form-control"
                  style={{ width: "100%" }}
                  value={productType}
                  onChange={(e) => setProductType(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label>Image</label>
                <input
                  type="file"
                  id="myfile"
                  name="myfile"
                  className="form-control"
                  style={{ width: "100%" }}
                  onChange={handleImage}
                />
              </div>
              {uploadImage && (
                <div className="col-md-12 my-2">
                  <img src={uploadImage} height="100px" widtth="100px" />
                </div>
              )}
            </div>
          </div>

          <div className="row mt-5">
            <div className="col-xl-8 col-lg-8 col-12 col-md-9" style={{display: "flex",alignItems:"center", justifyContent: "center"}}>
              <Link to={"/prodType"}>
                <button type="button" href="#" className="btn btn-addCategory ">
                  Back
                </button>
              </Link>
            </div>
            <div className="col-xl-4 col-lg-4 col-12 col-md-3">
              <button
                onClick={submitProductType}
                href="#"
                className="btn btn-addCategory "
              >
                Add Product Type
              </button>
            </div>

          </div>
        </div>
      </main>
    </Layout>
  );
}
