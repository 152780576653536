import React, { useState } from 'react'
import Header from "../DashboardLayout/Header";
import { Outlet } from 'react-router';
import Sidebar from './Sidebar';

export default function Layout(props) {
  // const navigate = useNavigate()
  return (
    <>
      <Header />
      {props.sidebar ? <Sidebar /> : null}
      {props.children}
    </>
  )
}
