import React, { useState } from "react";
import { useNavigate } from "react-router";
import { ToastContainer, toast } from "react-toastify";
import { requestData } from "../../Utils/HttpClient";
import "react-toastify/dist/ReactToastify.css";
import Layout from "../../Layout/DashboardLayout/Index";
import { Link } from "react-router-dom";

export default function CreateVendor() {

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const navigate = useNavigate()

  const submitVendor = async () => {
    //   alert();
    if (name !== "" &&
      email !== "" &&
      password !== "" &&
      phone !== "" &&
      address !== "") {
      let emailRegex = /^([\-\.0-9a-zA-Z]+)@([\-\.0-9a-zA-Z]+)\.([a-zA-Z]){1,7}$/;
      if (emailRegex.test(email)) {
        let phoneRegex = /^[7-9]\d{9}$/;
        if (phoneRegex.test(phone)) {
          let dataSend = {
            name: name,
            email: email,
            password: password,
            phone: phone,
            address: address
          }
          // console.log(dataSend)
          // return false
          let result = await requestData(
            "admin/add_vendor",
            "POST",
            dataSend
          )
          console.log("AddVdr", result)
          if (result && result.status) {
            toast("Vendor created Successfully!", {
              position: "bottom-right",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            })
            setTimeout(function () {
              navigate("/vendor")
            }, 2000)
          } else {
            toast("Email Or Phone No Already Exist !! Try another", {
              position: "bottom-right",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            })
          }
        }
        else {
          toast.error("Enter a valid phone number")
        }

      }
      else {
        toast.error("Enter a valid email")
      }
    } else {
      toast("All Fields are Required", {
        position: "bottom-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
  }


  return (
    <Layout sidebar>
      <ToastContainer />
      <main className="enrollment">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-4 col-12">
              <div className="description">
                <h4 className="text-base mb-2">Description</h4>
                <p className="text-body ">
                  Add your vendor details and necessary information here
                </p>
              </div>
            </div>
            <div className="col-xl-8 col-lg-8 col-md-8 col-12 createCategoryCustom">
              <div className="form-group">
                <label>Name</label>
                <input
                  type="text"
                  placeholder="Enter Name"
                  className="form-control"
                  style={{ width: "100%" }}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label>Email</label>
                <input
                  type="email"
                  placeholder="Enter Email"
                  className="form-control"
                  style={{ width: "100%" }}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label>Password</label>
                <input
                  type="password"
                  placeholder="Enter Password"
                  className="form-control"
                  style={{ width: "100%" }}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label>Phone No</label>
                <input
                  type="number"
                  pattern="(7|8|9)\d{9}"
                  placeholder="Enter Phone No"
                  className="form-control"
                  style={{ width: "100%" }}
                  value={phone}
                  onChange={(e) => setPhone(e.target.value.slice(0, 10))}
                />
              </div>
              <div className="form-group">
                <label>Address</label>
                <textarea
                  id="w3review"
                  name="w3review"
                  rows={4}
                  cols={50}
                  style={{ width: "100%", resize: "none" }}
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                />
              </div>
            </div>
          </div>

          <div className="row mt-5">
            <div className="col-xl-8 col-lg-8 col-12 col-md-9" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
              <Link to={"/vendor"}>
                <button type="button" href="#" className="btn btn-addCategory ">
                  Back
                </button>
              </Link>
            </div>
            <div className="col-xl-4 col-lg-4 col-12 col-md-3">
              <button onClick={submitVendor} className="btn btn-addCategory ">
                Add Vendor
              </button>
            </div>
          </div>
        </div>
      </main>
    </Layout>
  );
}
