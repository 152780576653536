import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    user: {
        email: ""
    },
    message: "",
    token: "",
    authenticate: false,
    loading: true
};

export const authSlice = createSlice({
    name: "auth",
    initialState: initialState,
    reducers: {
        signupSuccess: (state, action) => {
            state.message = 'User registered successfully.'
        },
        loginRequest: (state, action) => {
            state.loading = true
        },
        loginSuccess: (state, action) => {
            state.loading = false
            state.authenticate = true;
            state.user.email = action.payload;
        },
        logOut: (state, action) => {
            state.authenticate = false
            state.user.email = ""
        }
    },
});

export const authAction = authSlice.actions;

export default authSlice.reducer;
