import React, { useEffect, useState } from "react";
import profile from "../../images/avatar-placeholder.svg";
import PaginatedItems from "../../Component/Pagination/Index";
import Layout from "../../Layout/DashboardLayout/Index";
import { PROFILE_IMG_URL, requestData } from "../../Utils/HttpClient";
import { toast } from "react-toastify";
import { ColorRing } from "react-loader-spinner";
import Pagination from "../../Component/Pagination/Pagination";

export default function UserContact() {
  const [fetchPartner, setFetchPartner] = useState([]);
  const [status, setStatus] = useState("");
  const [postPerPage, setPostPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [userId, setUserId] = useState("");

  useEffect(() => {
    getAllPartnerContact();
  }, []);

  const lastPostIndex = currentPage * postPerPage;
  const firstPostIndex = lastPostIndex - postPerPage;
  const currentPost = fetchPartner.slice(firstPostIndex, lastPostIndex);

  const getAllPartnerContact = async () => {
    let data = await requestData("admin/fetchVendorContact", "GET");
    // console.log("GetColor", data.data);
    // return false
    console.log("Contact", data);
    if (data && data.status) {
      setFetchPartner(data.data);
      console.log("AllUserContact", data.data);
    }
  };

  return (
    <Layout sidebar>
      <main className="enrollment">
        <div className="container-fluid">
          {/* <div className="col-md-12 col-12 col-xl-12 col-lg-12 px-0" style={{ marginBottom: "20px" }}>
            <div className="categoryBox custCategoryWrap" style={{ display: "flex" }} >
              <div className="categoryLeft">
                <h1 className="text-heading">Customers Contact</h1>
              </div>
              <div className="formBtn">
                <div className="form-group has-search">
                  <span className="fa fa-search form-control-feedback" />
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Type your query and press enter"
                  />
                </div>
                <a href="#" className="btn btn-addCategory">
                  + Add
                </a>
              </div>
            </div>
          </div> */}

          <div className="users">
            <div className="card recentTable_Card">
              <div className="card-body p-0">
                <div className="table-responsive">
                  <table className="table table-hover mb-0">
                    <thead>
                      <tr>
                        <th>SL</th>
                        <th className="text-center">Name</th>
                        <th className="text-center">Email</th>
                        <th>Phone No</th>
                        {/* <th>Available wallet points</th> */}
                        <th>Address</th>
                        {/* <th>Message</th> */}
                        {/* <th className='text-right'>Actions</th> */}
                      </tr>
                    </thead>
                    {currentPost.length > 0 ? (
                      currentPost.map((item, index) => (
                        <tbody key={index}>
                          <tr>
                            <td className="text-center">{index + 1}</td>
                            <td className="text-center">{item.name}</td>
                            <td className="text-center">{item.email}</td>
                            <td className="text-center">{item.phone}</td>
                            {/* <td>5000</td> */}
                            <td>{item.address}</td>
                            {/* <td>
                         {item.message}
                        </td> */}
                            <td></td>
                          </tr>
                        </tbody>
                      ))
                    ) : (
                      <span
                        style={{
                          display: "flex",
                          position: "fixed",
                          zIndex: "1000",
                          left: "0",
                          top: "0",
                          right: "0",
                          margin: "0 auto",
                          background: "rgba(0,0,0,0.3)",
                          justifyContent: "center",
                          height: "100vh",
                          alignItems: "center",
                        }}
                      >
                        <ColorRing
                          visible={true}
                          id="colorRing"
                          height="80"
                          width="80"
                          ariaLabel="blocks-loading"
                          wrapperStyle={{}}
                          wrapperclassName="blocks-wrapper"
                          colors={[
                            "#f21322",
                            "#0cb3f0",
                            "#f58716",
                            "#f00cd9",
                            "#d50cf0",
                          ]}
                        />
                      </span>
                    )}
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Pagination
          totalPosts={fetchPartner.length}
          postPerPage={postPerPage}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
        />
        {/* <PaginatedItems itemsPerPage={4}/> */}
      </main>
    </Layout>
  );
}
